import React, {Fragment} from 'react';
import {createPortal} from 'react-dom';
import styles from './Modal.module.css';
import Backdrop from '../Backdrop';

const Modal = props => {

  const {
    maxWidth = '900px',
    backdropCloseHandler,
    children
  } = props

  const inlineStyle = {
    maxWidth: maxWidth
  }

  return createPortal (
    <Fragment>
      <div className={styles.Modal} style={inlineStyle}>
        {children}
      </div>
      { backdropCloseHandler ? <Backdrop show close={ backdropCloseHandler} /> : <Backdrop show /> }
    </Fragment>,
    document.getElementById('root')
  )
  
}

export default Modal;