
import React from 'react'
import styles from './AdminContentManager.module.css';
import PageList from '../PageList';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import ContentWrapper from '../ContentWrapper';
import MobileHeader from '../MobileHeader';

const Admincontentmanager = props => {

  return (
    <ContentWrapper>
      <div className={styles.Admincontentmanager}>
        <MobileHeader>Admin Content Manager</MobileHeader>
        {props.topLevelPages ? <PageList pagelist={props.topLevelPages} navLevel={0} /> : null}
      </div>
    </ContentWrapper>
  )

}

const mapStateToProps = ({pages}) => {
  return {
      topLevelPages: pages.topLevelPages,
      pages: pages.pages
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPageList: () => dispatch(actions.fetchPageList())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Admincontentmanager)
