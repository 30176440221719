import React from 'react';
import {createPortal} from 'react-dom';
import styles from './MobileHeader.module.css';

const MobileHeader = props => {
  return createPortal (
    <div className={styles.MobileHeader}>
      {props.children}
    </div>,
    document.getElementById('root')
  )
}

export default MobileHeader