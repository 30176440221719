import { connect } from "react-redux";
import React from "react";
import timeago from "epoch-timeago";
import unixTime from "unix-time";
// import styled from 'styled-components'

import * as actions from "../../store/actions";
import Button from "../Button";
import ListItem from "../ListItem";

import styles from "./UserTokenItem.module.css";

const TimeAgo = ({ time }) => {
	if (new Date(time).getTime() > 0) {
		return (
			<time dateTime={new Date(time).toISOString()}>{timeago(time)}</time>
		);
	} else return false;
};

const UserTokenItem = (props) => {
	const { user, activeUserArray, deleteUserToken } = props;
	// Delete user token if older than 2 weeks
	if (unixTime(new Date()) - user.timestamp >= 604800 * 2)
		deleteUserToken({ token: user.id });

	// Delete user token if exists as Active
	if (
		activeUserArray.filter((activeUser) => activeUser.email === user.email)
			.length > 0
	)
		deleteUserToken({ token: user.id });

	const emailBody = `Hi, you have been granted reader access to the Ped Surg Mobile App.\n
  Visit the following link to create an account using your email ${user.email} as your login and create a password:\n
  https://manual.bayareapediatricsurgery.org/create-account#${user.id}\n
  Once logged in, you can use the app with your web browser on desktop or phone. You can also install it on your home screen as an app using the instructions at: https://manual.bayareapediatricsurgery.org/app`;

	const emailSubject = `PedSurg Manual Account Creation`.replace(
		/ /gi,
		"%20",
	);

	const mailTo = `mailto:${
		user.email
	}?subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`;

	return (
		<ListItem key={user.id} className={styles.UserTokenItem}>
			<span className={styles.TokenData}>
				<span className={styles.Email}>{user.email}</span>
				<span className={styles.Role}>{user.role}</span>
				<span className={styles.Token}>{user.id}</span>
				<span className={styles.DateAdded}>
					Date Added: <TimeAgo time={user.timestamp * 1000} />
				</span>
			</span>
			<span className={styles.Buttons}>
				<Button
					fontSize="0.8rem"
					onClick={() => deleteUserToken({ token: user.id })}
				>
					Delete
				</Button>
			</span>
			<div style={{ width: "100%" }}>
				<a style={{ fontSize: "0.9rem" }} href={mailTo}>
					Email token link to user
				</a>
			</div>
		</ListItem>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteUserToken: (payload) => {
			dispatch(actions.deleteUserToken(payload));
		},
	};
};

export default connect(null, mapDispatchToProps)(UserTokenItem);
