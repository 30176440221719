import { connect } from 'react-redux';
import React, {useState} from 'react';
import { useAlert } from 'react-alert';

import * as actions from '../../store/actions';
import * as ROUTES from '../../constants/routes'
import Button from '../Button'
import checkValidity from '../../util/validation';
import Form from '../Form';

import styles from './PasswordChange.module.css';
import ContentWrapper from '../ContentWrapper';
import Title from '../Title';

const PasswordChange = props => {

  const [controls, setControls] = useState({
    email: {
      order: 0,
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Email'
      },
      value: '',
      validation: {
        required: true,
        isEmail: true
      },
      valid: false,
      touched: false
    },
    passwordOld: {
      order: 1,
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Old Password'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    passwordNew: {
      order: 2,
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'New Password'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  });

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  const alert = useAlert()

  const submitHandler = (event) => {
    event.preventDefault();
    if (!controls.passwordNew.valid) {
      alert.error('Password must be at least 8 characters')
    } else {
      props.changePassword({
        email: controls.email.value.toLowerCase(),
        passwordOld: controls.passwordOld.value,
        passwordNew: controls.passwordNew.value,
      })
      props.history.push(ROUTES.LANDING)
    }
  }

  return (
    <ContentWrapper>
      <div className={styles.PasswordChange}>
        <Title>Change Password</Title>
        <p>Enter email and old password, along with new passsword.</p>
        <Form className={styles.Form}
          controls={controls}
          changeHandler={changeHandler}
          submitHandler={submitHandler}
          submitName="Change Password"
        />
        <Button className='gray' style={{marginTop:'1rem'}} onClick={()=> props.history.push(ROUTES.LANDING)}>Cancel</Button>
      </div>
    </ContentWrapper>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (payload) => dispatch(actions.changePassword(payload))
  }
}

export default connect(null,mapDispatchToProps)(PasswordChange)