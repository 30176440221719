
import React, {useState} from 'react'
import styles from './AdminAddContact.module.css';
import List from '../List';
import Form from '../Form';
import checkValidity from '../../util/validation';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

const AdminAddContact = props => { 

  const {categoryOrder,categories} = props
  
  let categorySelect = categoryOrder.map(id => {return {value: id, displayValue: categories[id].name} })

  categorySelect = [{value: "", displayValue: "Select Type", selected:true, disabled:true}, ...categorySelect]

  const phoneTypes = [
    {value: "", displayValue: "Select Type", selected:true},
    {value: "Office", displayValue: "Office"},
    {value: "Cell", displayValue: "Cell"},
    {value: "Cell/VOALTE", displayValue: "Cell/VOALTE"},
    {value: "Pager", displayValue: "Pager"},
    {value: "Fax", displayValue: "Fax"},
  ]

  const controlsInitialState = {
    categoryId: {
      label: false,
      order: 0,
      elementType: 'select',
      elementConfig: {
        type: 'category',
        options: categorySelect 
      },
      value: props.categoryId,
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      autoFocus: true,
      className: styles.Category
    },
    firstName: {
      label: false,
      order: 2,
      elementType: 'input',
      elementConfig: {
        type: 'firstName',
        placeholder: 'First name'
      },
      value: props.firstName,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      inputWrapperStyles: styles.NameWrapper,
      className: styles.FirstName
    },
    lastName: {
      label: false,
      order: 3,
      elementType: 'input',
      elementConfig: {
        type: 'lastName',
        placeholder: 'Last name'
      },
      value: props.lastName,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      className: styles.LastName,
      inputWrapperStyles: styles.NameWrapper,
    },
    honorific: {
      label: false,
      order: 4,
      elementType: 'input',
      elementConfig: {
        type: 'honorific',
        placeholder: 'Honorific (optional)'
      },
      value: props.honorific,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      className: styles.Honorific,
      inputWrapperStyles: styles.NameWrapper,
    },
    title: {
      label: false,
      order: 5,
      elementType: 'input',
      elementConfig: {
        type: 'title',
        placeholder: 'Title or Description'
      },
      value: props.title,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      className: styles.Title
    },
    email: {
      label: false,
      order: 6,
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Email'
      },
      value: props.email,
      validation: {
        required: true,
        isEmail: true
      },
      valid: false,
      touched: false,
      className: styles.Email
    },
    phone1: {
      label: false,
      order: 7,
      elementType: 'input',
      elementConfig: {
        type: 'phone',
        placeholder: 'Phone'
      },
      value: props.phone1,
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      className: styles.Phone,
      inputWrapperStyles: styles.PhoneWrapper,
    },
    phone1type: {
      label: false,
      order: 8,
      elementType: 'select',
      elementConfig: {
        type: 'phoneType',
        options: phoneTypes
      },
      value: props.phone1type,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      className: styles.PhoneType,
      inputWrapperStyles: styles.PhoneTypeWrapper,
    },
    phone2: {
      label: false,
      order: 9,
      elementType: 'input',
      elementConfig: {
        type: 'phone',
        placeholder: 'Phone'
      },
      value: props.phone2,
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      className: styles.Phone,
      inputWrapperStyles: styles.PhoneWrapper,
    },
    phone2type: {
      label: false,
      order: 10,
      elementType: 'select',
      elementConfig: {
        type: 'phoneType',
        options: phoneTypes
      },
      value: props.phone2type,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      className: styles.PhoneType,
      inputWrapperStyles: styles.PhoneTypeWrapper,
    },
    phone3: {
      label: false,
      order: 11,
      elementType: 'input',
      elementConfig: {
        type: 'phone',
        placeholder: 'Phone'
      },
      value: props.phone3,
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      className: styles.Phone,
      inputWrapperStyles: styles.PhoneWrapper,
    },
    phone3type: {
      label: false,
      order: 12,
      elementType: 'select',
      elementConfig: {
        type: 'phoneType',
        options: phoneTypes
      },
      value: props.phone3type,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      className: styles.PhoneType,
      inputWrapperStyles: styles.PhoneTypeWrapper,
    }
  }
  const [controls, setControls] = useState(controlsInitialState);

  const changeHandler = (event, id) => { 
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  const submitHandler = (event) => {
    event.preventDefault();

    let contact = {} 
    for (var key in controls) { 
      contact = {...contact, [key]:controls[key].value }
    }

    props.edit ? props.editContact({id: props.contactId, contact: contact}) : props.addContact(contact)

    setControls( {...controls,
      categoryId: {...controls.categoryId, valid: false, touched: false, value: props.categoryId },
      firstName: {...controls.firstName, valid: false, touched: false, value: props.firstName },
      lastName: {...controls.lastName, valid: false, touched: false, value: props.lastName },
      honorific: {...controls.honorific, valid: false, touched: false, value: props.honorific },
      title: {...controls.title, valid: false, touched: false, value: props.title },
      email: {...controls.email, valid: false, touched: false, value: props.email },
      phone1: {...controls.phone1, valid: false, touched: false, value: props.phone1 },
      phone1type: {...controls.phone1type, valid: false, touched: false, value: props.phone1type },
      phone2: {...controls.phone2, valid: false, touched: false, value: props.phone2 },
      phone2type: {...controls.phone2type, valid: false, touched: false, value: props.phone2type },
      phone3: {...controls.phone3, valid: false, touched: false, value: props.phone3 },
      phone3type: {...controls.phone3type, valid: false, touched: false, value: props.phone3type },
      } 
    ) 

    props.submitCallback()
  }

  return (
    <div className={styles.ListItem}>
      {props.edit ? <h2>Edit Contact</h2> : <h2>Add Contact</h2>}
      <Form className={styles.AdminAddContactForm} 
        controls={controls}
        changeHandler={changeHandler}
        submitHandler={submitHandler} 
        submitName={props.submitName}
        submitWrapperClassName={styles.SubmitWrapper}
      >
        <List>
        </List>
      </Form>
    </div>
  )
}

const mapStateToProps = ({contacts}) => {
  return {
    categoryOrder: contacts.categoryOrder,
    categories: contacts.categories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addContact: (payload) => dispatch(actions.addContact(payload)),
    editContact: (payload) => dispatch(actions.editContact(payload)),
  }
}

AdminAddContact.defaultProps = { //Using this to help structure 
  categoryId: "",
  contactId: "",
  firstName: "",
  lastName: "",
  honorific: "",
  title: "",
  email: "",
  priority: 0,
  submitName: "Add",
  phone1: "",
  phone1type: "",
  phone2: "",
  phone2type: "",
  phone3: "",
  phone3type: "",
  edit: false, // set to true to make it trigger save edit of contact on submit
  submitCallback: () => false
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminAddContact)
