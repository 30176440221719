
import React, {Fragment} from 'react'
import styles from './PageListItem.module.css';
import ListItem from '../ListItem';
import PageList from '../PageList'
import { connect } from 'react-redux';
import Modal from '../Modal';
import useToggle from 'react-use-toggle';
import AdminPageEdit from '../AdminPageEdit';
import Button from '../Button';
import Icon from 'react-icons-kit';
import {chevronUp} from 'react-icons-kit/fa/chevronUp';
import {chevronDown} from 'react-icons-kit/fa/chevronDown'
import * as actions from '../../store/actions'

const _Pagelistitem = props => {

  const {
    id,
    navLevel,
    page
  } = props

  const [editModal, toggleEdit] = useToggle(false);

  const editHandler = () => {
    toggleEdit()
  }

  const deleteHandler = () => {
    props.deletePage({parentId:props.parentId,id:props.id})
  }

  const changePriority = (change) => {
    props.setPageOrder({
      id: id,
      parentId: page.parentId || false,
      shift: change
    })
  }

  const PriorityBtnStyleUp = {
    padding: '.1rem .4rem .3rem',
    borderRadius: '3px 0 0 3px',
    height: '1rem'
  }
  const PriorityBtnStyleDown = {
    padding: '.1rem .4rem .3rem',
    borderRadius: '0 3px 3px 0',
    height: '1rem'
  }

  return page ? (
    <Fragment>
      <ListItem className={[styles.ListItem, styles[`Level${navLevel}`]].join(' ')} > 
        <div className={styles.PageControls}>
            <div className={styles.PriorityShiftButtons}>
                <Button className='gray' style={PriorityBtnStyleUp} onClick={() => changePriority(-1)}><Icon size={12} icon={chevronUp}/></Button>
                <Button className='gray' style={PriorityBtnStyleDown} onClick={() => changePriority(1)}><Icon size={12} icon={chevronDown}/></Button>
            </div>
            <div className={[styles.Title, styles[`Level${navLevel}`]].join(' ')}>{page.title}</div>
          <div className={styles.EditButtons}>
            <Button onClick={editHandler}>Edit</Button>
            <Button className='gray' style={{marginLeft: '.5rem'}} onClick={deleteHandler}>Delete</Button>
          </div>
        </div>
         {(navLevel < 1) ? <PageList pagelist={page.subpages} parentId={id} navLevel={navLevel + 1} /> : null}

      </ListItem>
      {editModal ? 
        <Modal backdropCloseHandler={toggleEdit}>
          <AdminPageEdit 
            submitCallback={toggleEdit} 
            id={id}
            parentId={page.parentId}
            title={page.title}
            priority={page.priority}
            contentBlocks={page.contentBlocks}
             />
        </Modal>
      : null
      }
    </Fragment>
  ) : null
}

const mapStateToProps = ({pages}, ownProps) => {
  return {
      page: pages.pages ? pages.pages[ownProps.id] : false,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deletePage: (payload) => dispatch(actions.deletePage(payload)),
    editPage: (payload) => dispatch(actions.editPage(payload)),
    setPageOrder: (payload) => dispatch(actions.setPageOrder(payload))
  }
}

const PageListItem = connect(mapStateToProps,mapDispatchToProps)(_Pagelistitem) //Make new component so that this works recursively

_Pagelistitem.defaultProps = {
  navLevel: 0,
  parentId: false
}

export default PageListItem

//<div onClick={() => getPages(page.id)}>+</div>