
import { BrowserRouter as Router, Route, Switch, NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import {addressCardO} from 'react-icons-kit/fa/addressCardO';
import {book} from 'react-icons-kit/fa/book';
import {edit} from 'react-icons-kit/fa/edit';
import {logOut} from 'react-icons-kit/ionicons/logOut';
import {cog} from 'react-icons-kit/icomoon/cog';
//import {users} from 'react-icons-kit/fa/users';
import Icon from 'react-icons-kit';
import React, {Fragment} from 'react'

import * as ROUTES from '../../constants/routes'
import AdminContentManager from '../AdminContentManager'
import AdminOnlyRoute from './AdminOnlyRoute';
import AdminUserManager from '../AdminUserManager'
import ContactList from '../ContactList';
import PasswordChange from '../PasswordChange'
import PasswordReset from '../PasswordReset';
import FirebaseAction from '../FirebaseAction'
import PrivateRoute from './PrivateRoute';
import ReadingPane from '../ReadingPane'
import Signup from '../Signup';
import SplashScreen from '../SplashScreen';
import Toolbar from '../Toolbar';
import ToolbarButton from '../Toolbar/ToolbarButton';
import CreateAccount from '../CreateAccount'
import MobileAppInstall from '../MobileAppInstall'

import styles from './AppRouter.module.css';

const ToolbarDisplay = props => {

  const {verified} = props

  return (verified === 'reader' || verified === 'admin') ? (
    <Toolbar>
      <ToolbarButton><NavLink activeClassName={styles.Active} to={'/manual/'}><Icon size={24} icon={book}/></NavLink></ToolbarButton>
      <ToolbarButton><NavLink activeClassName={styles.Active} to={ROUTES.CONTACTS}><Icon size={24} icon={addressCardO}/></NavLink></ToolbarButton>
      {(verified === 'admin') ? 
        <Fragment>
          <ToolbarButton><NavLink activeClassName={styles.Active} to={ROUTES.CONTENT_MANAGER}><Icon size={24} icon={edit}/></NavLink></ToolbarButton>
          <ToolbarButton><NavLink activeClassName={styles.Active} to={ROUTES.USER_MANAGER}><Icon size={24} icon={cog}/></NavLink></ToolbarButton>
        </Fragment> : null }
      <ToolbarButton><NavLink exact activeClassName={styles.Active} to={ROUTES.LANDING}><Icon size={24} icon={logOut}/></NavLink></ToolbarButton>
    </Toolbar>
  ) : null
}

const AppRouter = props => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path={ROUTES.MANUAL} component={ReadingPane} />
        <PrivateRoute path={ROUTES.CONTACTS} component={ContactList} />
        <AdminOnlyRoute path={ROUTES.CONTENT_MANAGER} component={AdminContentManager} />
        <AdminOnlyRoute path={ROUTES.USER_MANAGER} component={AdminUserManager} />
        <Route path={ROUTES.SIGNUP} component={Signup} />
        <Route path={ROUTES.PASSWORD_RESET} component={PasswordReset} />
        <Route path={ROUTES.FIREBASE_ACTION} component={FirebaseAction} />
        <Route path={ROUTES.PASSWORD_CHANGE} component={PasswordChange} />
        <Route path={ROUTES.CREATE_ACCOUNT} component={CreateAccount} />
        <Route path={ROUTES.LANDING} exact component={SplashScreen} />
        <Route path={ROUTES.IOS_INSTALL} exact component={MobileAppInstall} />
      </Switch>
      <ToolbarDisplay {...props}/>
    </Router>
  )
}

const mapStateToProps = ({auth}) => {
  return {
      verified: auth.verified
  }
}

export default connect(mapStateToProps)(AppRouter)