import * as actionTypes from './actionTypes';
import {db} from '../../firebase/firebase';
import * as actions from './index';

// Listener types
export const PAGES_LISTENER = 'PAGES_LISTENER';
export const CONTACTS_LISTENER = 'CONTACTS_LISTENER'
export const CONTACTS_CATEGORIES_LISTENER = 'CONTACTS_CATEGORIES_LISTENER'
export const TOP_ORDER_LISTENER = 'TOP_ORDER_LISTENER'
export const USERS_LISTENER = 'USERS_LISTENER'
export const USER_TOKENS_LISTENER = 'USERS_TOKENS_LISTENER'
export const _ON = '_ON'
export const _OFF = '_OFF'

export const subscribeListener = (listener) => (dispatch, getState) => {

    const isListening = getState().global.isListening || {};

    if (!isListening || !isListening[listener]) {
        switch (listener) {
            case TOP_ORDER_LISTENER:
                dispatch(topOrderListener())
                break;
            case PAGES_LISTENER:
                dispatch(makeListener({listener:listener,database: db.pages,action:[actions.fetchPageListSuccess,actions.setSluggedPages]}))
                break;
            case CONTACTS_LISTENER:
                dispatch(makeListener({listener:listener,database: db.contacts,action:actions.fetchContactListSuccess}))
                break;
            case CONTACTS_CATEGORIES_LISTENER:
                dispatch(makeListener({listener:listener,database: db.contactsCategories,action:actions.fetchContactCategoriesSuccess}))
                break;
            case USERS_LISTENER:
                dispatch(makeListener({listener:listener,database: db.users,action:actions.fetchUserListSuccess}))
                break;
            case USER_TOKENS_LISTENER:
                dispatch(makeListener({listener:listener,database: db.userTempCode,action:actions.fetchUserTokensSuccess}))
                break;
            default: return false
        }
    }
}

// Create unsubscribe array variable so it can be called later
let unsubscribe = []

export const makeListener = ({listener,database,action}) => dispatch => {
    unsubscribe[listener] = database.onSnapshot(querySnapshot => {
        let query = {}
        querySnapshot.forEach(function(doc) {
            query = {...query,[doc.id]:doc.data()}
        });

        if (Array.isArray(action)) {
            action.forEach( a =>  dispatch(a(query)) )
        } else {
            dispatch(action(query))
        }

      }, (error) => {
        console.log(error);
        dispatch({
            type: actionTypes.UNSUBSCRIBE_LISTENER,
            listener: listener,
        })
        dispatch({type: listener + _OFF})
    })

    dispatch({
        type: actionTypes.SUBSCRIBE_LISTENER,
        listener: listener,
    })
    dispatch({type: listener + _ON})
};

export const subscribeAllListeners = (role) => (dispatch,getState) => {
    const verified = role || getState().auth.verified

    if (verified === 'reader' || verified === 'admin') {
        dispatch(subscribeListener(TOP_ORDER_LISTENER))
        dispatch(subscribeListener(PAGES_LISTENER))
        dispatch(subscribeListener(CONTACTS_LISTENER))
        dispatch(subscribeListener(CONTACTS_CATEGORIES_LISTENER))
    }
    if (verified === 'admin') {
        dispatch(subscribeListener(USERS_LISTENER))
        dispatch(subscribeListener(USER_TOKENS_LISTENER))
    }
}

export const topOrderListener = () => dispatch => {
    const listener = TOP_ORDER_LISTENER;

    unsubscribe[listener] = db.topOrderDoc.onSnapshot(doc => {
        if (doc.exists) {
            const categoryOrder = doc.data().contactCategoryOrder
            const topPageOrder = doc.data().topPageOrder

            dispatch(fetchTopPageOrderSuccess({topPageOrder:topPageOrder}))
            dispatch(fetchContactCategoryOrderSuccess({categoryOrder: categoryOrder }))
        } else {
            console.log('global top order for contact categories does not exist')
        }
    }, (error) => {
            console.log(error)
            dispatch({
                type: actionTypes.UNSUBSCRIBE_LISTENER,
                listener: listener,
            })
            dispatch({type: listener + _OFF})
        }
    );
    dispatch({
        type: actionTypes.SUBSCRIBE_LISTENER,
        listener: listener,
    })
    dispatch({type: listener + _ON})
};

const fetchTopPageOrderSuccess = ({topPageOrder}) => {
    return {type: actionTypes.SET_TOPLEVELPAGES, topLevelPages:topPageOrder }
}

const fetchContactCategoryOrderSuccess = ({categoryOrder}) => {
    return {type: actionTypes.SET_CONTACT_CATEGORY_ORDER, categoryOrder }
}

export const unsubscribeListener = (listener) => (dispatch,getState) => {
    const isListening = getState().global.isListening || {};
    if (isListening[listener] && unsubscribe[listener]) { // only fire off if listener is on and unsubscribe exists
        unsubscribe[listener]();
        dispatch({
            type: actionTypes.UNSUBSCRIBE_LISTENER,
            listener: listener,
        })
        dispatch({type: listener + _OFF})
    }
}

export const unsubscribeAllListeners = () => (dispatch) => {
    dispatch(unsubscribeListener(TOP_ORDER_LISTENER))
    dispatch(unsubscribeListener(PAGES_LISTENER))
    dispatch(unsubscribeListener(CONTACTS_LISTENER))
    dispatch(unsubscribeListener(CONTACTS_CATEGORIES_LISTENER))
    dispatch(unsubscribeListener(USERS_LISTENER))
    dispatch(unsubscribeListener(USER_TOKENS_LISTENER))
}