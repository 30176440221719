import * as actionTypes from './actionTypes';
import {db} from '../../firebase/firebase';

export const fetchContactList = () => {
    return dispatch => {
        dispatch(fetchContactCategories())
        return db.contacts.get()
            .then(function(querySnapshot) {
                let contacts = {}
                querySnapshot.forEach(function(doc) {
                    contacts = {...contacts,[doc.id]:doc.data()}
                });
                dispatch(fetchContactListSuccess(contacts))
            })
            .catch(function(error) {
                console.log("fetchContactList: Error getting document:", error);
            });
    }
}

export const fetchContactListSuccess = (payload) => {
    return {type: actionTypes.GET_CONTACTS, contacts: payload }
}

export const addContact = (payload) => {
    const {categoryId} = payload
    return dispatch => {
        return db.contacts.add(payload)
          .then(function(contactRef) {
                console.log("addContact: Document written with ID: ", contactRef.id)
                dispatch(addContactSuccess({ id:contactRef.id, contact: payload}));
                dispatch(setContactOrder({id:contactRef.id, categoryId: categoryId}));
          })
          .catch(function(error) {
              console.error("addContact: Error adding document: ", error);
          })
    }
}

const addContactSuccess = ({id, contact}) => {
    return {type: actionTypes.ADD_CONTACT, id: id, contact: contact }
}

export const editContact = (payload) => {
    let {id, contact} = payload

    return (dispatch, getState) => {
        const contactState = getState().contacts.contacts[id];

        return db.contacts.doc(id).set(contact,{merge: true})
        .then(function() {
            console.log("editContact: Document overwritten with ID: ", id);
            contact = {...contact, id:id }
            dispatch(editContactSuccess({contact:contact, id:id }));

            if (contactState.categoryId !== contact.categoryId) {
                // Remove from old category
                if (contactState.categoryId) { // only run if it has a category
                    let categoryOld = getState().contacts.categories[contactState.categoryId];
                    const contactArrayFilter = categoryOld.contacts.filter(contact => contact !== id)
                    categoryOld = {
                        ...categoryOld,
                        contacts: contactArrayFilter
                    }
                    dispatch(editContactCategory({categoryId:contactState.categoryId,category:categoryOld}));
                }
                // Add to new category
                let categoryNew = getState().contacts.categories[contact.categoryId];
                categoryNew = {
                    ...categoryNew,
                    contacts: [
                        ...categoryNew.contacts,
                        id
                    ]
                }
                dispatch(editContactCategory({categoryId:contact.categoryId,category:categoryNew}));
            }
        })
        .catch(function(error) {
            console.error("editContact: Error adding document: ", error);
        });
    }
}

const editContactSuccess = ({id, contact}) => {
    return {type: actionTypes.EDIT_CONTACT, id: id, contact: contact } 
}

export const deleteContact = (payload) => {
    const {id} = payload;

    return (dispatch, getState) => {
        const categoryId = getState().contacts.contacts[id].categoryId || false;

        return db.contacts.doc(id).delete().then(function() {
            console.log(`deleteContact: Document ${id} successfully deleted!`);
            dispatch(deleteContactSuccess(id));

            if (categoryId) { //if there is a category id then remove contact from category
                let categoryOld = getState().contacts.categories[categoryId];
                    const contactArrayFilter = categoryOld.contacts.filter(contact => contact !== id)
                    categoryOld = {
                        ...categoryOld,
                        contacts: contactArrayFilter
                    }
                    dispatch(editContactCategory({categoryId:categoryId,category:categoryOld}));
            }
        }).catch(function(error) {
            console.error("deleteContact: Error removing document: ", error);
        });
    }
}

const deleteContactSuccess = (payload) => {

    return { type: actionTypes.DELETE_CONTACT, id:payload }
}

// Contacts Categories

export const fetchContactCategories = () => {
    return dispatch => {
        return db.contactsCategories.get()
            .then(function(querySnapshot) {
                let categories = {}
                querySnapshot.forEach(function(doc) {
                    categories = {...categories,[doc.id]:doc.data()}
                });
                dispatch(fetchContactCategoriesSuccess(categories))
            })
            .catch(function(error) {
                console.log("fetchContactCategories: Error getting document:", error);
            });
    }
}

export const fetchContactCategoriesSuccess = (payload) => {
    return {type: actionTypes.GET_CONTACT_CATEGORIES, categories: payload }
}

export const addContactCategory = (payload) => {
    const category = {...payload, contacts: []}
    return dispatch => {
        return  db.contactsCategories.add(category)
        .then(function(contactCatRef) {
            console.log("addContactCategory: Document written with ID: ", contactCatRef.id);
            dispatch(addContactCategorySuccess({ categoryId:contactCatRef.id, contactCategory: category}));
            dispatch(setContactCategoryOrder({categoryId:contactCatRef.id}))
        })
        .catch(function(error) {
            console.error("addContactCategory: Error adding document: ", error);
        })
    }
}

const addContactCategorySuccess = ({categoryId, contactCategory}) => {
    return {type: actionTypes.ADD_CONTACT_CATEGORY, categoryId, contactCategory }
}

export const editContactCategory = (payload) => {
    let {categoryId, category} = payload
    return dispatch => {
        return  db.contactsCategories.doc(categoryId).set(category,{merge: true})
        .then(function() {
            console.log("editContactCategory: Document overwritten with ID: ", categoryId);
            dispatch(editContactCategorySuccess({category:category, categoryId:categoryId }));
        })
        .catch(function(error) {
            console.error("editContactCategory: Error adding document: ", error);
        });
    }
}

const editContactCategorySuccess = ({categoryId, category}) => {
    return {type: actionTypes.EDIT_CONTACT_CATEGORY, categoryId, category }
}

export const deleteContactCategory = (payload) => {

    const {categoryId} = payload;

    return (dispatch, getState) => {
        const contacts = getState().contacts.contacts
        const categoryOrder = getState().contacts.categoryOrder
        let newContacts = {}
        Object.keys(contacts).forEach(id => {
            if (contacts[id].categoryId === categoryId) {
                newContacts = {...newContacts, [id]: {...contacts[id], categoryId: false}}
            } else newContacts = {...newContacts, [id]: contacts[id]}
        })

        const newCategoryOrder = categoryOrder.filter(item => item !== categoryId)

        return db.contactsCategories.doc(categoryId).delete().then(function() {
            console.log(`deleteContactCategory: Document ${categoryId} successfully deleted!`);
            dispatch(deleteContactCategoryFromOrder({categoryOrder:newCategoryOrder }) )
            dispatch(deleteContactCategorySuccess({categoryId:categoryId}))
            dispatch(fetchContactListSuccess(newContacts))
        }).catch(function(error) {
            console.error("deleteContactCategory: Error removing document: ", error);
        });
    }
}

const deleteContactCategoryFromOrder = ({categoryOrder}) => {
    return (dispatch, getState) => {
        return db.topOrderDoc.set({contactCategoryOrder:categoryOrder},{merge: true})
            .then(function() {
                console.log("deleteContactCategoryFromOrder: Updated Contact Category Order");
                dispatch({
                    type: actionTypes.SET_CONTACT_CATEGORY_ORDER,
                    categoryOrder: categoryOrder
                })
            })
            .catch(function(error) {
                console.error("deleteContactCategoryFromOrder: Error adding document: ", error);
            });
    }
}

const deleteContactCategorySuccess = ({categoryId}) => {
    return {type:actionTypes.DELETE_CONTACT_CATEGORY, categoryId}
}

export const setContactOrder = ({id, categoryId, shift = false}) => {

    return (dispatch, getState) => {
        const categories = getState().contacts.categories;
        const contactOrder = (categoryId && categories[categoryId].contacts) ? categories[categoryId].contacts : []

        const arrayShift = (arr, item, shiftAmount) => {
            const itemIndex = arr.indexOf(item);
            let newArr = [...arr]
            newArr.splice(itemIndex, 1)
            if (shiftAmount > 1 || shiftAmount < -1) {
                // Only allow shifting up or down by one
                shiftAmount = 0
            } else if (itemIndex === 0 && shiftAmount < 0) {
                // Do not down shift if first already
                shiftAmount = 0
            } else if (itemIndex === arr.length -1 && shiftAmount > 0) {
                // Do not shift up if already last
                shiftAmount = 0
            }
            newArr.splice(itemIndex + shiftAmount, 0, item)
            return newArr
        }

        const contactArray = (contactOrder.includes(id) && shift) ? arrayShift(contactOrder, id, shift) : [...contactOrder, id]

        if (categoryId) {
            dispatch(editContactCategory({
                categoryId: categoryId,
                category: {
                    name: categories[categoryId].name,
                    contacts: contactArray
                }
            }))
        }
    }
}

export const setContactCategoryOrder = ({categoryId, shift = false}) => {

    return (dispatch, getState) => {
        const contacts = getState().contacts;
        const categoryOrder = contacts.categoryOrder || []

        const arrayShift = (arr, item, shiftAmount) => {
            const itemIndex = arr.indexOf(item);
            let newArr = [...arr]
            newArr.splice(itemIndex, 1)
            if (shiftAmount > 1 || shiftAmount < -1) {
                // Only allow shifting up or down by one
                shiftAmount = 0
            } else if (itemIndex === 0 && shiftAmount < 0) {
                // Do not down shift if first already
                shiftAmount = 0
            } else if (itemIndex === arr.length -1 && shiftAmount > 0) {
                // Do not shift up if already last
                shiftAmount = 0
            }
            newArr.splice(itemIndex + shiftAmount, 0, item)
            return newArr
        }

        const newCategoryOrder = (categoryOrder.includes(categoryId) && shift) ? arrayShift(categoryOrder, categoryId, shift) : [...categoryOrder, categoryId]

        return db.topOrderDoc.set({contactCategoryOrder:newCategoryOrder},{merge: true})
            .then(function() {
                console.log("setContactCategoryOrder: Updated Contact Category Order");
                dispatch({
                    type: actionTypes.SET_CONTACT_CATEGORY_ORDER,
                    categoryOrder: newCategoryOrder
                })
            })
            .catch(function(error) {
                console.error("setContactCategoryOrder: Error adding document: ", error);
            });
    }
}