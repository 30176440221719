import React, {Fragment, useState} from 'react';
import useToggle from 'react-use-toggle';
import styles from './ContentPaneBlock.module.css';
import getMarkup from '../../util/getMarkup';
import ImgZoom from '../ImgZoom';

const ContentPaneBlock = props => {

  const {header,contentHTML} = props

  //const imgRegex = /<img([\w\W]+?)[/]?>/gi
  //console.log(contentHTML.match(imgRegex));

  const [zoomImage, toggleZoom] = useToggle(false);
  const [clickedImg, setClickedImage] = useState(false);

  const clickHandler = (e) => {
    // `target` is the element the click was on (the div we hooked or an element
    // with in it), `currentTarget` is the div we hooked the event on
    const el = e.target.closest("IMG");
    // Only trigger zoom if it's an img and image is wider than the window width.
    if (el && e.currentTarget.contains(el) && e.target.width < e.target.naturalWidth) {
        // ...do your state change...
        setClickedImage({src: e.target.src, width: e.target.naturalWidth, height: e.target.naturalHeight });
        toggleZoom();
    }
  }

  return (
    <Fragment>
      <div className={styles.ContentPaneBlock}>
        {header ? <h2 className={styles.Title}>{header}</h2> : null}
        <div className={styles.Content} onClick={clickHandler} dangerouslySetInnerHTML={getMarkup(contentHTML)}/>
      </div>
      { zoomImage ? <ImgZoom fullWidth img={clickedImg} backdropCloseHandler={toggleZoom} /> : null }
    </Fragment>
  )
}

export default ContentPaneBlock