import React from "react";
import styles from "./MobileAppInstall.module.css";
import ContentWrapper from "../ContentWrapper";
import Title from "../Title";
import screen1 from "../../assets/how-to-images/how-to-1.jpg";
import screen2 from "../../assets/how-to-images/how-to-2.jpg";
import screen3 from "../../assets/how-to-images/how-to-3.jpg";

const MobileAppInstall = (props) => {
	return (
		<ContentWrapper>
			<div className={styles.MobileAppInstall}>
				<Title>Mobile App</Title>
				<p>
					PedSurg Manual is a webapp that can be added to your home
					screen as a mobile app.
				</p>
				<h2>How to Install to your iOS Home Screen</h2>
				<p>
					On your iOS device visit the{" "}
					<a href="https://manual.bayareapediatricsurgery.org/">
						home page of the House Manual
					</a>
				</p>
				<ol className={styles.HowTo}>
					<li>
						<h3>Click on the Share button</h3>{" "}
						<img src={screen1} alt="screen shot of Share button" />
					</li>
					<li>
						<h3>Then select "Add to Home Screen"</h3>{" "}
						<img
							src={screen2}
							alt="screen shot of Add to Home Screen"
						/>
					</li>
					<li>
						<h3>On the dialog click Add</h3>{" "}
						<img src={screen3} alt="screen shot of dialog" />
					</li>
				</ol>
				<p>
					It should show up as an icon on your home screen and will
					open full screen like an app. You can then sign in like
					normal.
				</p>
				<h2>How to Install to your Android Home Screen using Chrome</h2>
				<p>
					In Chrome visit the{" "}
					<a href="https://manual.bayareapediatricsurgery.org/">
						home page of the House Manual
					</a>
				</p>
				<ol className={styles.HowTo}>
					<li>Tap the three-dot icon in the upper-right.</li>
					<li>Find and select “Add to Home screen” from the menu.</li>
					<li>In the pop-up tap “Add.”</li>
					<li>
						Select “Add to home screen” to add the icon to the next
						open spot, or tap and hold the icon then drag it to your
						desired location on the home screen.
					</li>
				</ol>
				<p>
					It should show up as an icon on your home screen and will
					open full screen like an app. You can then sign in like
					normal.
				</p>
			</div>
		</ContentWrapper>
	);
};

export default MobileAppInstall;
