import * as actionTypes from './actionTypes';
import {firebaseAuth, db} from '../../firebase/firebase';
import unixTime from 'unix-time';
import * as actions from './index';

const emailRedirectUrl = process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT

export const signIn = ({email,password}) => {
    return dispatch => {
        firebaseAuth.signInWithEmailAndPassword(email, password).then(authUser => {
            // signin functions handled by signInPersistCheck
        }).catch(function(catchError) {
            dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
            console.log("signIn: login failed", catchError)
        });
    }
}

export const addSignInTimestamp = (payload) => {
    const {signInTimestamp, uid, email} = payload;
    return dispatch => {

        db.authLog.doc(uid).set({
            email: email,
            lastSignInTimestamp: signInTimestamp
        }, { merge: true })
        .then(function() {
            dispatch({
                type: actionTypes.SIGN_IN_TIMESTAMP,
                uid,
                signInTimestamp,
                email
            })
        }).catch(function(catchError) {
            dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
            console.log("addSignInTimestamp: add timestamp info to database failed", catchError)
        })
    }
}

export const signInPersistCheck = () => {
    return dispatch => {
        firebaseAuth.onAuthStateChanged(authUser => {
            if (authUser) {
                console.log('signInPersistCheck: state changed user ID', authUser.uid)
                return db.users.doc(authUser.uid).get()
                .then(function(doc) {
                    if (doc.exists) {
                        const userdata = doc.data()
                        dispatch(signInSuccess({
                            uid: authUser.uid,
                            email: userdata.email,
                            role: userdata.role,
                        }))
                        dispatch(verified(userdata.role));
                        dispatch(actions.subscribeAllListeners(userdata.role));
                        dispatch(addSignInTimestamp({email: userdata.email, uid: authUser.uid, signInTimestamp: unixTime(new Date())}))
                    } else {
                        dispatch(actions.setAlert({message:'Your account has not been granted access.', alertType: 'error'}))
                        dispatch(signOut())
                    }
                })
                .catch(function(catchError) {
                    //dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
                    console.log("signInPersistCheck: Error getting user document:", catchError);
                });
            }
        })
    }
}

export const signInSuccess = (payload) => {
    return {type:actionTypes.SIGN_IN, user:payload}
}

export const verified = (payload) => {
    return {type:actionTypes.VERIFIED, verified:payload}
}

export const signOut = () => {
    return dispatch => {
        firebaseAuth.signOut().then(()=>{
            dispatch(actions.unsubscribeAllListeners());
            dispatch(signOutSucesss())
            dispatch(verified(undefined))
        }).catch((catchError)=>{
            dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
            console.log("signOut: logout failed", catchError)
        });
    }
}

export const signOutSucesss = () => {
    return {type:actionTypes.SIGN_OUT}
}

export const requestAccess = ({email,password,role = 'requested'}) => {
    return dispatch => {
        firebaseAuth.createUserWithEmailAndPassword(email, password).then(authUser => {
            authUser.user.sendEmailVerification({
                url: emailRedirectUrl,
            });
            dispatch(requestAccessSuccess())
            db.users.doc(authUser.user.uid).set({
                email: email,
                role: role
            }).then(function() {
                console.log("requestAccess: add user info to database for", authUser.user.uid)
            }).catch(function(catchError) {
                dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
                console.log("requestAccess: add user info to database failed", catchError)
            })
        }).catch(function(catchError) {
            dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
            console.log("requestAccess: create user failed", catchError)
        });
    }
}

export const requestAccessSuccess = () => {
    return {type:actionTypes.REQUEST_ACCESS}
}

export const createAccount = ({email,password,hash}) => {
    return dispatch => {
        return db.userTempCode.doc(hash).get() //fetch hash token data
            .then(function(doc) {
                if (doc.exists && doc.data().email === email) { // if this hash exists and the email matches

                    const userdata = doc.data()
                    //console.log('userdata',userdata)

                    firebaseAuth.createUserWithEmailAndPassword(email, password).then(authUser => { // Create a use account
                        const userid = authUser.user.uid
                        console.log("createAccount: added user auth", userid)
                        db.users.doc(userid).set({ // Create User database entry
                            email: email,
                            role: userdata.role, // use role from hash data
                            hash: hash, // hash required for Firestore create rules
                        }).then(function() {
                            console.log("createAccount: added user info to database for", userid)
                            dispatch(actions.setAlert({message:"Your user account is now active!", alertType: 'success'}))
                            dispatch(signInSuccess({
                                id: userid,
                                email: email,
                                role: userdata.role,
                            }))
                            dispatch(verified(userdata.role));
                            dispatch(actions.subscribeAllListeners(userdata.role));
                            dispatch(addSignInTimestamp({email: email, uid: userid, signInTimestamp: unixTime(new Date())}))
                            dispatch(actions.deleteUserToken({token:hash}));
                        }).catch(function(catchError) {
                            dispatch(actions.setAlert({message:"failed to add user account. " + catchError.message, alertType: 'error'}))
                            console.log("createAccount: add user info to database failed", catchError)
                        })
                    }).catch(function(catchError) {
                        dispatch(actions.setAlert({message:"create user failed" + catchError.message, alertType: 'error'}))
                        console.log("createAccount: create user failed", catchError)
                    });
                } else {
                    dispatch(actions.setAlert({message:'The Create Account link token you used is either wrong or no longer exists', alertType: 'error'}))
                    console.log("createAccount: create account hash token does not exist")
                }
            })
            .catch(function(catchError) {
                dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
                console.log("createAccount: Error getting user hash document:", catchError);
            });
    }
}

export const fetchHashToken = ({hash,email}) => {

    return dispatch => {
        return db.userTempCode.doc(hash).get()
            .then(function(doc) {
                if (doc.exists && doc.data().email === email) {
                    console.log("Document data:", doc.data());
                } else {
                    // doc.data() will be undefined in this case
                    console.log("fetchHashToken: No such document!");
                }
            })
            .catch(function(error) {
                console.log("fetchHashToken: Error getting document:", error);
            });
    }
}

export const resetPassword = ({email}) => {
    return dispatch => {
        firebaseAuth.sendPasswordResetEmail(email).then(function() {
            // Email sent.
            dispatch(resetPasswordSuccess())
          }).catch(function(catchError) {
            dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
            // An error happened.
          });
    }
}

export const resetPasswordSuccess = () => {
    return {type:actionTypes.RESET_PASSWORD}
}

export const changePassword = ({email=false,passwordOld=false,oobCode=false,passwordNew}) => {
    return dispatch => {
        if (email) { // If they are logging in right now
            firebaseAuth.signInWithEmailAndPassword(email, passwordOld).then(authUser => {

                return db.users.doc(authUser.user.uid).get()
                .then(function(doc) {
                    if (doc.exists) {
                        dispatch(signInSuccess({
                            uid: authUser.user.uid,
                            email: doc.data().email,
                            role: doc.data().role,
                            emailVerified: authUser.user.emailVerified
                        }))
                        const verifiedRole = (authUser.user.emailVerified) ? doc.data().role : false
                        dispatch(verified(verifiedRole));

                        // Update Password
                        firebaseAuth.currentUser.updatePassword(passwordNew).then(function() {
                            //Update successful
                            dispatch(changePasswordSuccess())
                        }).catch(function(catchError) {
                            dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
                            console.log(catchError)
                        // An error happened.
                        });
                    }
                })
                .catch(function(catchError) {
                    dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
                    console.log("changePassword: Error getting user document:", catchError);
                });

            }).catch(function(catchError) {
                dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
                console.log("changePassword: login failed", catchError)
            });
        } else if (oobCode) { //if they got here via firebase reset email
            // Update Password
            firebaseAuth.verifyPasswordResetCode(oobCode)
            .then(email => {
                dispatch(resetPasswordActionCodeSuccess());
                firebaseAuth.confirmPasswordReset( oobCode, passwordNew )
                    .then(resp => {
                        // Password reset has been confirmed and new password updated.
                        dispatch(changePasswordSuccess())
                    })
                    .catch(e => {
                          // Error occurred during confirmation. The code might have
                          // expired or the password is too weak. alert(e);
                    });
            }).catch(catchError => {
            // Invalid or expired action code. Ask user to try to reset the password again
            dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
            console.log("changePassword:",catchError);
            });
        }
    }
}

export const changePasswordSuccess = () => {
    return {type:actionTypes.RESET_PASSWORD}
}

export const resetPasswordActionCodeSuccess = () => {
    return {type:actionTypes.RESET_PASSWORD_ACTION_CODE_SUCCESS}
}
