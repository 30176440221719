import * as actionTypes from './actionTypes';
//import {db} from '../../firebase/firebase';

export const setAlert = (payload) => {
    return dispatch => {
        if (payload) {
            const {message, alertType} = payload
            dispatch({
                type: actionTypes.ALERT_MSG,
                message,
                alertType
            })
            
        } else {
            dispatch({
                type: actionTypes.ALERT_MSG,
                message: null,
                alertType: null
            })
        }
    }
}