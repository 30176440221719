import { connect } from 'react-redux';
import React from 'react'

import {transformObjectToArray,dynamicSortMultiple} from '../../util/dynamicSort';
import List from '../List';
import UserTokenItem from '../UserTokenItem';

import styles from './UserTokens.module.css';

const UserTokens = ({userTokens, userList}) => {

  const activeUserArray = transformObjectToArray(userList);
  const userTokenArray = transformObjectToArray(userTokens);

  if (userTokens && Object.keys(userTokens).length !== 0) {
    return (
      <div className={styles.UserTokens}>
        <h2 className={styles.UserTokensTitle}>User Account Tokens</h2>
        <List className={styles.UserTokenList}>
          {userTokenArray.sort(dynamicSortMultiple('role','email')).map( user => <UserTokenItem key={user.id} activeUserArray={activeUserArray} user={user} /> )}
        </List>
      </div>
    )
  } else return false;
}

const mapStateToProps = ({users}) => {
  return {
      userList: users.userList,
      userTokens: users.userTokens
  }
}

export default connect(mapStateToProps, null)(UserTokens)