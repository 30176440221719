import * as actionTypes from './actionTypes';
import {db} from '../../firebase/firebase';
import unixTime from 'unix-time';
import { v1 as uuidv1 } from 'uuid';
import * as actions from './index';

export const fetchUserList = () => {
    let users = {}

    return dispatch => {
        return db.users.get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    users = {...users,[doc.id]:doc.data()}
                });
                dispatch(fetchUserListSuccess(users))
                dispatch(fetchUserTimestamps())
            })
            .catch(function(error) {
                console.log("fetchUserList: Error getting document:", error);
            });
    }
}

export const fetchUserListSuccess = (users) => {
    return {type:actionTypes.GET_USER_LIST, users:users}
}

export const fetchUserTokensSuccess = (tokens) => {
    return {type:actionTypes.GET_USER_TOKENS, tokens:tokens}
}

export const fetchUserTimestamps = () => {
    return dispatch => {
        let users = {}
        db.authLog.get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                users = {...users,[doc.id]:doc.data()}
            });
            dispatch({
                type: actionTypes.GET_USER_TIMESTAMPS,
                users
            })
        })
        .catch(function(error) {
            console.log("fetchUserTimestamps: Error getting document:", error);
        });
    }
}

export const createUserTempCode = ({email,role = 'reader'}) => {

    const hash = uuidv1()
    const timestamp = unixTime(new Date())

    return dispatch => {

        db.userTempCode.doc(hash).set({
            email: email,
            role: role,
            timestamp: timestamp
        }, { merge: true })
        .then(function() {
            dispatch({
                type: actionTypes.ADD_USER,
                hash,
                email,
                timestamp,
                role
            })
        }).catch(function(catchError) {
            dispatch(actions.setAlert({message:catchError.message, alertType: 'error'}))
            console.log("createUserTempCode: add user temp code failed", catchError)
        })
    }
}

export const deleteUserToken = (payload) => {
    let {token} = payload

    return dispatch => {
        return db.userTempCode.doc(token).delete().then(function() {
            console.log(`deleteUserToken: Document ${token} successfully deleted!`);
            dispatch(deleteUserTokenSuccess(token));
        }).catch(function(error) {
            console.error("deleteUserToken: Error removing document: ", error);
        });
    }
}

export const deleteUserTokenSuccess = (token) => {
    return {type:actionTypes.DELETE_USER_TOKEN, token}
}

export const deleteUser = (payload) => {
    let {uid} = payload

    return dispatch => {
        return db.users.doc(uid).delete().then(function() {
            console.log(`deleteUser: Document ${uid} successfully deleted!`);
            dispatch(deleteUserSuccess(uid));
        }).catch(function(error) {
            console.error("deleteUser: Error removing document: ", error);
        });
    }
}

export const deleteUserSuccess = (uid) => {
    return {type:actionTypes.DELETE_USER, uid}
}

export const changeRole = (payload) => {
    let {uid, email, role} = payload

    return dispatch => {
        return db.users.doc(uid).set({email:email,role:role},{merge: true})
        .then(function() {
            console.log("changeRole: User overwritten with ID: ", uid);
            dispatch(changeRoleSuccess({uid: uid, email: email, role: role }));
        })
        .catch(function(error) {
            console.error("changeRole: Error changing user document: ", error);
        });
    }
}

export const changeRoleSuccess = ({uid, email, role}) => {
    return {type:actionTypes.CHANGE_ROLE, uid, email, role}
}