
import React from 'react'
import styles from './Signup.module.css';

const Signup = props => {
  return (
    <div className={styles.Signup}></div>
  )
}

export default Signup