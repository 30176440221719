
import styled from 'styled-components'

const Button = styled.button`
  border-radius: 3px;
  background-color: var(--navy-blue);
  background: linear-gradient(180deg, var(--navy-blue-mid) 0%,var(--navy-blue) 30%,var(--navy-blue-dark) 100%);
  color: var(--very-light-blue);
  border: 1px solid var(--navy-blue-deep);
  padding: .4rem .8rem .4rem;
  font-weight: 700;
  text-transform: uppercase;
  font-size: ${props => props.fontSize || '0.9rem'};
  transition: all .5s;
  text-shadow: -1px -1px 1px var(--navy-blue-deep);
  &.block {
    display: block;
    margin: 1rem auto;
  }
  &.large {
    font-size: ${props => props.fontSize || '1.2rem'};
  }
  &:focus {
    outline: 0;
  }
  &.gray {
    background-color: var(--gray);
    background: linear-gradient(180deg, var(--gray-mid) 0%,var(--gray) 30%,var(--gray-dark) 100%);
    border: 1px solid var(--gray-deep);
  }
  &.burgundy {
    background-color: var(--burgundy);
    background: linear-gradient(180deg, var(--burgundy-mid) 0%,var(--burgundy) 30%,var(--burgundy-dark) 100%);
    border: 1px solid var(--burgundy-dark);
  }
  &.plainwhite {
    border: 0;
    background: 0;
    text-decoration: none;
    color: var(--off-white);
    display: block;
    padding: 0;
    margin: 0 auto;
    text-shadow: none;
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
  }
  &.none {
    border: 0;
    background: 0;
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 0;
    margin: 0 auto;
    text-shadow: none;
    text-transform: none;
    font-size: inherit;
    font-weight: inherit;
  }
  @media (hover: hover) {
    &:hover {
        background-color: var(--navy-blue-dark);
        background: linear-gradient(180deg, var(--navy-blue-light) 0%,var(--navy-blue-mid) 30%,var(--navy-blue) 100%);
        color: #ffffff;
    }
    &.plainwhite:hover {
      color: var(--light-orange);
      border: 0;
      background: none;
    }
    &.none:hover {
      color: inherit;
      border: 0;
      background: none;
    }
  }
`

export default Button