import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import camelCase from 'lodash/camelCase'
import React from 'react';
import useToggle from 'react-use-toggle';

import ContentPane from '../ContentPane'
import ContentWrapper from '../ContentWrapper';
import ManualNav from '../ManualNav';
import MenuBtn from '../MenuBtn';
import MobileHeader from '../MobileHeader'
import ScrollToTopHOC from '../ScrollToTopHOC/ScrollToTopHOC';
import SideMenu from '../SideMenu';
// import { ReactComponent as Logo } from '../../assets/logo.svg';
import styles from './ReadingPane.module.css';

const ReadingPane = props => {

  const {
    topLevelPages,
    sluggedPages = {},
    currentPage = 'introduction',
    } = props;

  const [menuState, toggleMenu] = useToggle(false)

  const currentPageSlugCamelCase = camelCase(currentPage)
  const currentPageId = sluggedPages[currentPageSlugCamelCase] ? sluggedPages[currentPageSlugCamelCase].id : false;

  // Only fire close menu if it is open
  const closeMenu = menuState ? () => toggleMenu() : null

  return (
    <ScrollToTopHOC>
      <ContentWrapper>
        <MobileHeader>UCSF PedSurg House Manual <MenuBtn clickHandler={toggleMenu} anim='Squeeze' active={menuState} /></MobileHeader>
        <div className={styles.Readingpane}>
          {currentPageId ?
            <ContentPane id={currentPageId} slug={currentPage}/>
            :
            <div className={styles.LogoWrapper}></div>
          }
        </div>
        <SideMenu menuState={menuState} closeMenu={toggleMenu}>
          {topLevelPages ? <ManualNav pagelist={topLevelPages} closeMenu={closeMenu} navLevel={0} /> : null}
        </SideMenu>
      </ContentWrapper>
    </ScrollToTopHOC>
  )
}

const mapStateToProps = ({pages}) => {
  return {
    topLevelPages: pages.topLevelPages,
    pages: pages.pages,
    sluggedPages: pages.sluggedPages,
    currentPage: pages.currentPage
  }
}

export default connect(mapStateToProps)(withRouter(ReadingPane))