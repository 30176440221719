
import { connect } from 'react-redux';
import {chevronDown} from 'react-icons-kit/fa/chevronDown';
import {chevronUp} from 'react-icons-kit/fa/chevronUp';
import Icon from 'react-icons-kit';
import React, {Fragment} from 'react'
import useToggle from 'react-use-toggle';

import * as actions from '../../store/actions';
import AdminAddContact from '../AdminAddContact'
import Button from '../Button'
import ListItem from '../ListItem'
import Modal from '../Modal'

import styles from './ContactListItem.module.css';

const ContactListItem = props => {

  const [editModal, toggleEdit] = useToggle(false);

  const {id,contact,admin} = props


  let displayStyle = {display: "inline-block"} 

  const changePriority = (change) => {
    props.setContactOrder({
      id: id,
      categoryId: contact.categoryId,
      shift: change
    })
  }

  const PriorityBtnStyleUp = {
    padding: '.1rem .4rem .3rem',
    borderRadius: '3px 0 0 3px',
    height: '1rem'
  }
  const PriorityBtnStyleDown = {
    padding: '.1rem .4rem .3rem',
    borderRadius: '0 3px 3px 0',
    height: '1rem'
  }
  
  if (contact) { // change to block if more than just title and one phone number
    if (contact.firstName || contact.lastName || contact.email || contact.phone2 || contact.phone3) { displayStyle = {display: "block"} }
  }

  return contact ? (
    <Fragment>
      <ListItem className={styles.ListItem}>
        {(props.verified === 'admin' && admin) ? <div className={styles.Buttons}>
          <div className={styles.PriorityShiftButtons}>
            <Button className='gray' style={PriorityBtnStyleUp} onClick={() => changePriority(-1)}><Icon size={12} icon={chevronUp}/></Button>
            <Button className='gray' style={PriorityBtnStyleDown} onClick={() => changePriority(1)}><Icon size={12} icon={chevronDown}/></Button>
          </div>
          <div className={styles.EditBtns}>
            <Button fontSize='0.8rem' onClick={toggleEdit}>Edit</Button> 
            <Button fontSize='0.8rem' onClick={()=>{props.deleteContact({id:id})}}>Delete</Button>
          </div>
        </div> : null}

        {contact.firstName ? <span className={styles.Name} style={displayStyle}>{contact.firstName} {contact.lastName} {contact.honorific}</span> : null}
        {contact.title ? <span className={styles.Title} style={displayStyle}>{contact.title}</span> : null}
        {contact.email ? <span className={styles.Email} style={displayStyle}><a href={`mailto:${contact.email}`}>{contact.email}</a></span> : null}
        {contact.phone1 ? <span className={styles.Phone} style={displayStyle}><a href={`tel:${contact.phone1}`}>{contact.phone1}</a> {contact.phone1type}</span> : null}
        {contact.phone2 ? <span className={styles.Phone} style={displayStyle}><a href={`tel:${contact.phone2}`}>{contact.phone2}</a> {contact.phone2type}</span> : null}
        {contact.phone3 ? <span className={styles.Phone} style={displayStyle}><a href={`tel:${contact.phone3}`}>{contact.phone3}</a> {contact.phone3type}</span> : null}

      </ListItem>
      {editModal ? <Modal backdropCloseHandler={toggleEdit}><AdminAddContact
        contactId={id}
        categoryId={contact.categoryId}
        firstName={contact.firstName}
        lastName={contact.lastName}
        honorific={contact.honorific}
        title={contact.title}
        email={contact.email}
        phone1={contact.phone1}
        phone1type={contact.phone1type}
        phone2={contact.phone2}
        phone2type={contact.phone2type}
        phone3={contact.phone3}
        phone3type={contact.phone3type}
        submitName="Save"
        edit
        submitCallback={toggleEdit}
      />
    </Modal> : null}
    </Fragment>
  ) : (null)
}
const mapStateToProps = ({auth, contacts}, ownProps) => {
  return {
    verified: auth.verified,
    contact: contacts.contacts[ownProps.id]
  }
}
const mapDispatchToProps = dispatch => {
  return {
    deleteContact: (payload) => dispatch(actions.deleteContact(payload)),
    editContact: (payload) => dispatch(actions.editContact(payload)),
    setContactOrder: (payload) => dispatch(actions.setContactOrder(payload))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ContactListItem)