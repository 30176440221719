
import { connect } from 'react-redux';
import React, {useState} from 'react'

import * as actions from '../../store/actions';
import * as ROUTES from '../../constants/routes'
import Button from '../Button'
import checkValidity from '../../util/validation';
import Form from '../Form';
import ContentWrapper from '../ContentWrapper'

import styles from './FirebaseAction.module.css';

const FirebaseAction = props => {

  const [controls, setControls] = useState({
    passwordNew: {
      order: 0,
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'New Password'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  });

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  function getQueryParam(name) {
    var q = window.location.search.match(new RegExp('[?&]' + name + '=([^&#]*)'));
    return q && q[1];
  }

  const mode = getQueryParam("mode");

  const submitHandler = (event) => {
    event.preventDefault();
    props.changePassword({
      oobCode: getQueryParam("oobCode"),
      apiKey: getQueryParam("apiKey"),
      passwordNew: controls.passwordNew.value,
    })
    props.history.push(ROUTES.LANDING)
  }

  switch (mode) {
    case 'resetPassword':
      return (
        <ContentWrapper>
          <div className={styles.FirebaseAction}>
            <h2>Reset Password</h2>
            <p>Enter new password</p>
            <Form className={styles.Form} 
              controls={controls}
              changeHandler={changeHandler}
              submitHandler={submitHandler}
              submitName="Change Password"
            />
            <Button style={{marginTop:'1rem'}} onClick={()=> props.history.push(ROUTES.LANDING)}>Cancel</Button>
          </div>
        </ContentWrapper>
      )
    case 'verifyEmail': 
      return (
        <ContentWrapper>
          <div className={styles.FirebaseAction}>
            <h2>Email Verified</h2>
            <p>Thank you for verifying your email. Access requests are handled manually by our staff. You will be notified when your access has been granted.</p>
            <Button style={{marginTop:'1rem'}} onClick={()=> props.history.push(ROUTES.LANDING)}>Return to Login</Button>
          </div>
        </ContentWrapper>
      )
    default: 
      console.log("No Params")
      return null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (payload) => dispatch(actions.changePassword(payload))
  }
}

export default connect(null,mapDispatchToProps)(FirebaseAction)