
import { connect } from 'react-redux';
import React, {Fragment} from 'react'
import useToggle from 'react-use-toggle';

import {transformObjectToArray} from '../../util/dynamicSort'
import * as actions from '../../store/actions'
import AdminAddContact from '../AdminAddContact'
import AdminAddContactCategory from '../AdminAddContactCategory/AdminAddContactCategory';
import Button from '../Button'

import ContentWrapper from '../ContentWrapper';
import MobileHeader from '../MobileHeader'
import Modal from '../Modal';
import List from '../List';
import ContactListCategory from '../ContactListCategory'
import ContactListItem from '../ContactListItem'

import styles from './ContactList.module.css';

const AdminTools = ({admin,toggleAdmin,toggleAdd,toggleAddCategory}) => {
  return (
    <div className={styles.AdminControls}>
      
      {admin ? <Fragment>  
          <ul className={styles.AdminButtons}>
            <li><Button fontSize='0.8rem' onClick={toggleAdmin}>Close Admin Edit</Button></li>
            <li><Button fontSize='0.8rem' onClick={toggleAddCategory} >Add Category</Button> </li>
            <li><Button fontSize='0.8rem' onClick={toggleAdd} >Add Contact</Button></li>
          </ul>
        </Fragment> 
        : 
        <ul className={styles.AdminButtons}><li><Button fontSize='0.8rem' onClick={toggleAdmin}>Admin Edit</Button></li></ul>}
    </div>
  )
}

const ContactList = props => {
  const [addModal, toggleAdd] = useToggle(false);
  const [addCategoryModal, toggleAddCategory] = useToggle(false);
  const [admin, toggleAdmin] = useToggle(false);

  const {
    categoryOrder = [],
    contacts,
    verified
  } = props

  const uncategorizedContacts = contacts ? transformObjectToArray(contacts).filter(contact => !contact.categoryId) : false

  return (
    <ContentWrapper>
      <MobileHeader>Contact List </MobileHeader>
      
      <div className={styles.ContactList}>
        {(verified === 'admin') ? <AdminTools admin={admin} toggleAdmin={toggleAdmin} toggleAddCategory={toggleAddCategory} toggleAdd={toggleAdd} /> : null }
        {(categoryOrder.length > 0) ? categoryOrder.map(categoryId => <ContactListCategory key={categoryId} categoryId={categoryId} admin={admin} />) : null }
        {(uncategorizedContacts && uncategorizedContacts.length > 0) ? 
          <Fragment>
            <h3>Other</h3>
            <List className={styles.List}>
              {uncategorizedContacts.map( contact => <ContactListItem key={contact.id} id={contact.id} admin={admin} /> )}
            </List>
          </Fragment>
          :null
        }
        
        {addModal ? <Modal maxWidth='630px' backdropCloseHandler={toggleAdd}><AdminAddContact submitCallback={toggleAdd} /></Modal>: null}
        {addCategoryModal ? <Modal maxWidth='500px' backdropCloseHandler={toggleAddCategory}><AdminAddContactCategory submitCallback={toggleAddCategory} /></Modal>: null}
      </div>
    </ContentWrapper>
  )
}

const mapStateToProps = ({contacts, auth}) => {
  return {
    categoryOrder: contacts.categoryOrder,
    contacts: contacts.contacts,
    verified: auth.verified
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContactCategoryOrder: payload => dispatch(actions.setContactCategoryOrder(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactList)