// Action types

// Listeners
export const SUBSCRIBE_LISTENER = 'SUBSCRIBE_LISTENER';
export const UNSUBSCRIBE_LISTENER = 'UNSUBSCRIBE_LISTENER';

// Manual content
export const GET_PAGES = 'GET_PAGES';
export const GET_PAGE = 'GET_PAGE';
export const SET_TOPLEVELPAGES = 'SET_TOPLEVELPAGES';
export const ADD_PAGE = 'ADD_PAGE';
export const DELETE_PAGE = 'DELETE_PAGE';
export const EDIT_PAGE = 'EDIT_PAGE';
export const ADD_PAGE_CONTENT = 'ADD_PAGE_CONTENT';
export const GET_PAGE_CONTENT = 'GET_PAGE_CONTENT';
export const SET_SUBPAGE_ARRAY = 'SET_SUBPAGE_ARRAY';
export const DELETE_ID_FROM_SUBPAGE_ARRAY = 'DELETE_ID_FROM_SUBPAGE_ARRAY';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SAVE_SLUGGED_PAGES = 'SET_SLUGGED_PAGES';
export const SET_TOPPAGE_ORDER = 'SET_TOPPAGE_ORDER';
export const SET_SUBPAGE_ORDER = 'SET_SUBPAGE_ORDER'

// Contact list
export const GET_CONTACTS = 'GET_CONTACTS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const GET_CONTACT_CATEGORIES = 'GET_CONTACT_CATEGORIES';
export const GET_CONTACT_CATEGORIES_ORDER = 'GET_CONTACT_CATEGORIES_ORDER';
export const ADD_CONTACT_CATEGORY = 'ADD_CONTACT_CATEGORY';
export const EDIT_CONTACT_CATEGORY = 'EDIT_CONTACT_CATEGORY';
export const DELETE_CONTACT_CATEGORY = 'DELETE_CONTACT_CATEGORY';
export const SET_CONTACT_CATEGORY_ORDER = 'SET_CONTACT_CATEGORY_ORDER';
export const SET_CONTACT_ORDER = 'SET_CONTACT_ORDER';

// Auth
export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_PERSIST_CHECK = 'SIGN_IN_PERSIST_CHECK'
export const SIGN_OUT = 'SIGN_OUT'
export const REQUEST_ACCESS = 'REQUEST_ACCESS'
export const VERIFIED = 'VERIFIED'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_ACTION_CODE_SUCCESS = 'RESET_PASSWORD_ACTION_CODE_SUCCESS'
export const ALERT_ERROR_MSG = 'ALERT_ERROR_MSG'
export const ALERT_INFO_MSG = 'ALERT_INFO_MSG'
export const ALERT_SUCCESS_MSG = 'ALERT_SUCCESS_MSG'
export const ALERT_MSG = 'ALERT_MSG'
export const SIGN_IN_TIMESTAMP = 'SIGN_IN_TIMESTAMP'

// User management
export const CREATE_USER = 'CREATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const GET_USER_LIST = 'GET_USER_LIST'
export const CHANGE_ROLE = 'CHANGE_ROLE'
export const GET_USER_TIMESTAMPS = 'GET_USER_TIMESTAMPS'
export const ADD_USER = 'ADD_USER'
export const GET_USER_TOKENS = 'GET_USER_TOKENS'
export const DELETE_USER_TOKEN = 'DELETE_USER_TOKEN'