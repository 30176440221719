
import React from 'react'
import styles from './ToolbarButton.module.css';

const Toolbarbutton = props => {
  return (
    <button className={props.className || styles.ToolbarButton} onClick={props.clickHandler} type={props.type} style={props.style}>{props.children ? props.children : props.name}</button>
  )
}

export default Toolbarbutton