
import React from 'react'
//import styles from './Submit.module.css';
import Button from '../../Button'

const Submit = props => {
  return (
    <Button className={props.submitClassName} style={props.style}>{props.name}</Button>
  )
}

export default Submit