import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom'

import * as actions from '../../store/actions';
import * as ROUTES from '../../constants/routes'
import Button from '../Button';
import checkValidity from '../../util/validation';
import ContentWrapper from '../ContentWrapper'
import Form from '../Form';
import Title from '../Title'

import styles from './CreateAccount.module.css';

const CreateAccount = props => {

  const {verified,signOut,createAccount} = props;

  const [controls, setControls] = useState({
    email: {
      order: 0,
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Email'
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
        //isUCSFemail: true,
      },
      valid: false,
      touched: false
    },
    password: {
      order: 1,
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Password'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  });

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  const alert = useAlert()
  const hash = props.history.location.hash ? props.history.location.hash.substring(1) : false

  const submitHandler = (event) => {
    event.preventDefault();
    if (!controls.password.valid) {
      alert.error('Password must be at least 8 characters')
    } else {
      createAccount({
        email: controls.email.value.toLowerCase(),
        password: controls.password.value,
        hash: hash
      })
      props.history.push(ROUTES.LANDING)
    }
  }

  useEffect(()=>{
    if (verified) signOut();
  },[verified,signOut])

  return hash ? (
    <ContentWrapper>
      <div className={styles.CreateAccount}>
        <Title>Create Account</Title>
          <p>
            Enter your UCSF email and create a password (password must be at least 8 characters). 
          </p>
          <p><em>You must be granted access to fully log in.</em></p>
        <Form className={styles.Form}
          controls={controls}
          changeHandler={changeHandler}
          submitHandler={submitHandler}
          submitName="Add Account"
        />
        <Button className='gray' style={{marginTop:'1rem'}} onClick={()=> props.history.push(ROUTES.LANDING)}>Cancel</Button>
      </div>
    </ContentWrapper>
  ) : <Redirect to={ROUTES.LANDING}/>
}

const mapStateToProps = ({auth}) => {
  return {
    verified: auth.verified
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createAccount: (payload) => dispatch(actions.createAccount(payload)),
    signOut: () => dispatch(actions.signOut())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateAccount)