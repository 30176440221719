
import React from 'react'
import { BrowserRouter as Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

const AdminOnlyRoute = ({ component:Component, verified, path, ...rest }) => {
  return (verified === "admin") ? <Route path={path} {...rest} ><Component/></Route> : <Redirect to="/" />
}

const mapStateToProps = ({auth}) => {
  return {
    verified: auth.verified
  }
}

export default connect(mapStateToProps)(AdminOnlyRoute)