import * as actionTypes from './actionTypes';
import {db} from '../../firebase/firebase';
import {transformObjectToArray, transformArrayToObject} from '../../util/dynamicSort';
import unixTime from 'unix-time';
import camelCase from 'lodash/camelCase';
import slugify from '../../util/slugify';

export const fetchPageList = () => { // Fetchs all pages

    let pages = {}

    return dispatch => {
        return db.pages.get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    pages = {...pages,[doc.id]:doc.data()}
                });
                dispatch(fetchPageListSuccess(pages))
                dispatch(setSluggedPages(pages))
            })
            .catch(function(error) {
                console.log("fetchPageList: Error getting document:", error);
            });
    }
}

export const fetchPageListSuccess = pages => {
    const epochtime = unixTime(new Date())
    return {
        type: actionTypes.GET_PAGES,
        pages,
        epochtime
    }
}

export const addSimplerSubpagesArray = pages => {
    const pagesArray = transformObjectToArray(pages).map( page => { 
        return {...page, subpages: (page.subpageIds && page.subpageIds.length > 0) ? page.subpageIds.map( subpage => subpage.id) : false } 
    })
    return transformArrayToObject(pagesArray);
}

export const setSluggedPages = pages => {
    return (dispatch, getState) => {
        pages = pages || getState().pages.pages

        const arrayToSlugsObject = (array) =>
        array.reduce((obj, item) => {
            obj[camelCase(item.slug)] = item
            return obj
        }, {})

        const sluggedPages = arrayToSlugsObject(transformObjectToArray(pages))
        const epochtime = unixTime(new Date())
        dispatch({
            type: actionTypes.SAVE_SLUGGED_PAGES,
            sluggedPages,
            epochtime
        })
    }
}

export const addPage = (payload) => {
    // Only adds title and priority. Used on AdminAddPage. Content added with editPage on AdminPageEdit

    const {parentId = false, title} = payload

    return (dispatch, getState) => {
        let pageSlug = slugify(title)
        if (parentId) {
            const parentSlug = getState().pages.pages[parentId].slug
            pageSlug = parentSlug + "-" + pageSlug
        }

        const page = {
            parentId: parentId,
            slug: pageSlug,
            title: title,
            modified: unixTime(new Date())
        }

        return db.pages.add(page)
          .then(function(pageRef) {
                console.log("addPage: Document written with ID: ", pageRef.id)
                dispatch(addPageSuccess({ id:pageRef.id, page: page}));
                dispatch(setPageOrder({id: pageRef.id, parentId:parentId}))
                dispatch(setSluggedPages());
          })
          .catch(function(error) {
              console.error("addPage: Error adding document: ", error);
          })
    }
}

export const addPageSuccess = ({id, page}) => {
    return { type: actionTypes.ADD_PAGE, id: id, page: page }
}

export const getPageContent = (payload) => {
    //payload = id

    let contentBlocks = {}

    return dispatch => {
        db.pages.doc(payload).collection("contentBlocks").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                contentBlocks = {...contentBlocks,[doc.id]:doc.data()}
            });
            dispatch(getPageContentSuccess({id: payload, contentBlocks: contentBlocks}))
        })
        .catch(function(error) {
            console.log("getPageContent: Error getting document:", error);
        });
    }
}

export const getPageContentSuccess = ({id, contentBlocks}) => {
    return {type: actionTypes.GET_PAGE_CONTENT, id: id, contentBlocks: contentBlocks }
}

export const setPageOrder = ({id, parentId = false, shift = false}) => {

    return (dispatch, getState) => {
        const pages = getState().pages;
        pages.topLevelPages = pages.topLevelPages || [] //empty array as base
        if (parentId) { pages.pages[parentId].subpages = pages.pages[parentId].subpages || [] } //empty array as base
        const pagelist = parentId ? [...pages.pages[parentId].subpages] : [...pages.topLevelPages] //spread operator ensures that it creates an empty array as a base; 

        const arrayShift = (arr, item, shiftAmount) => {
            const itemIndex = arr.indexOf(item);
            let newArr = [...arr]
            newArr.splice(itemIndex, 1)
            if (shiftAmount > 1 || shiftAmount < -1) {
                // Only allow shifting up or down by one
                shiftAmount = 0
            } else if (itemIndex === 0 && shiftAmount < 0) {
                // Do not down shift if first already
                shiftAmount = 0
            } else if (itemIndex === arr.length -1 && shiftAmount > 0) {
                // Do not shift up if already last
                shiftAmount = 0
            }
            newArr.splice(itemIndex + shiftAmount, 0, item)
            return newArr
        }

        const pageArray = (pagelist.includes(id) && shift) ? arrayShift(pagelist, id, shift) : [...pagelist, id]

        if (parentId) { // If has a parentId then it is a subpages
            return db.pages.doc(parentId).set({subpages:pageArray},{merge: true})
                .then(function() {
                    console.log("setPageOrder: Updated SubPage Order for page",id);
                    dispatch({
                        type: actionTypes.SET_SUBPAGE_ORDER,
                        parentId,
                        pageArray: pageArray
                    })
                })
                .catch(function(error) {
                    console.error("setPageOrder: Error adding document: ", error);
                });
        } else { // Top level page
            return db.topOrderDoc.set({topPageOrder:pageArray},{merge: true})
                .then(function() {
                    console.log("setPageOrder: Updated Top Page Order");
                    dispatch({
                        type: actionTypes.SET_TOPPAGE_ORDER,
                        pageArray: pageArray
                    })
                })
                .catch(function(error) {
                    console.error("setPageOrder: Error adding document: ", error);
                });
        }
    }
}

const deletePageFromOrderArray = ({id,parentId = false}) => {

    return (dispatch,getState) => {
        const pages = getState().pages;
        const pagelist = parentId ? [...pages.pages[parentId].subpages] : [...pages.topLevelPages] 

        if (pagelist.includes(id)) {
            const pageArray = pagelist.filter(page => page !== id)
            if (parentId) {
                dispatch({
                    type: actionTypes.SET_SUBPAGE_ORDER,
                    parentId,
                    pageArray: pageArray
                })
            } else if (!parentId) {
                dispatch({
                    type: actionTypes.SET_TOPPAGE_ORDER,
                    pageArray: pageArray
                })
            }
        }
    }
}

export const editPage = (payload) => {
    // Content added with editPage on AdminPageEdit
    let {id, page} = payload

    const modified = unixTime(new Date())

    return (dispatch, getState) => {
        let pageSlug = slugify(page.title)
        if (page.parentId) {
            const parentSlug = getState().pages.pages[page.parentId].slug
            pageSlug = parentSlug + "-" + pageSlug
        }

        page = {...page, slug: pageSlug, modified: modified}

        return db.pages.doc(id).set(page)
        .then(function() {
            console.log("editPage: Document overwritten with ID: ", id);
            page = {...page, id: id }
            dispatch(editPageSuccess({page:page, id:id }));
            dispatch(setSluggedPages());
        })
        .catch(function(error) {
            console.error("editPage: Error editing document: ", error);
        });
    }
}

export const editPageSuccess = ({id, page}) => {
    return {type: actionTypes.EDIT_PAGE, id: id, page: page }
}

export const deletePage = ({id, parentId = false}) => {

    return (dispatch, getState) => {

        const page = getState().pages.pages[id]

        return db.pages.doc(id).delete().then(function() {
            console.log(`deletePage: Document ${id} successfully deleted!`);
            dispatch(deletePageSuccess(id));
            dispatch(setSluggedPages());
            dispatch(deletePageFromOrderArray({id,parentId}));

            // Delete subpages if there are any
            if (page.subpageIds && page.subpageIds.length > 0) {
                page.subpageIds.forEach( subpage => {
                    dispatch(deletePage({id:subpage.id,parentId:false})) // parentId set to false as parent is already deleted
                })
            }

        }).catch(function(error) {
            console.error("deletePage: Error removing document: ", error);
        });
    }
}

export const deletePageSuccess = (payload) => {
    //payload = id

    return { type: actionTypes.DELETE_PAGE, id:payload }
}

export const setCurrentPage = (slug) => {
    return {
        type: actionTypes.SET_CURRENT_PAGE,
        slug
    }
}