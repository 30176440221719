
import React, {Fragment} from 'react';
import {createPortal} from 'react-dom';
import styles from './SideMenu.module.css';
import Backdrop from '../Backdrop';

const SideMenu = props => {

  const showStyle = {
    display: 'block'
  }

  return createPortal (
    <Fragment>
      <div className={styles.SideMenu} style={props.menuState ? showStyle : null}>
        {props.children}
      </div>
      <Backdrop show={props.menuState} close={props.closeMenu} zIndex />
    </Fragment>,
    document.getElementById('root')
  )
}

SideMenu.defaultProps = {
  menuState: false,
  toggleMenu: () => {},
}

export default SideMenu