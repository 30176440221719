
import { connect } from 'react-redux';
import React, {useState} from 'react'
import useToggle from 'react-use-toggle';

import * as actions from '../../store/actions';
import Button from '../Button';
import checkValidity from '../../util/validation';
import Form from '../Form';
import ListItem from '../ListItem'
import slugify from '../../util/slugify';

import styles from './AdminAddPage.module.css';

const Adminaddpage = props => {

  const controlsInitialState = {
    title: {
      label: false,
      order: 1,
      elementType: 'input',
      elementConfig: {
        type: 'title',
        placeholder: 'page title'
      },
      value: props.title,
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      inputWrapperStyles: styles.TitleWrapper
    }
  }
  const [controls, setControls] = useState(controlsInitialState);

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  const submitHandler = (event) => {
    event.preventDefault();
    const page = {
      title: controls.title.value,
      slug: slugify(controls.title.value),
      parentId: props.parentId
    }
    props.addPage(page);
    setControls( {...controls,title: {...controls.title, valid: false, touched: false, value: props.title } } ) 
    toggleAddPage()
  }

  const [addPage, toggleAddPage] = useToggle(false);

  const cancelBtnStyle = {marginLeft:".5rem"}

  return addPage ? (
    <ListItem className={styles.ListItem}>
      <Form className={styles.AdminAddPageForm} 
        controls={controls}
        changeHandler={changeHandler}
        submitHandler={submitHandler} 
        submitName={props.submitName}
        submitWrapperClassName={styles.SubmitWrapper}
        submitClassName={styles.Submit}
      />
      <Button onClick={toggleAddPage} style={cancelBtnStyle}>Cancel</Button>
    </ListItem>
  ) : (
    <ListItem className={styles.AddPageListItem}>
      <Button onClick={toggleAddPage} >{props.parentId ? "Add SubPage" : "Add Top Level Page" }</Button>
    </ListItem>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    addPage: (payload) => dispatch(actions.addPage(payload))
  }
}

Adminaddpage.defaultProps = {
  parentId: false, // Default false = no parent so top level
  title: "",
  priority: 0,
  submitName: "Add"
}

export default connect(null,mapDispatchToProps)(Adminaddpage)