import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import firebaseConfig from './firebase-config'

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore()

firestore.enablePersistence()
.catch(function(err) {
    if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});

const getCollection = collection => firestore.collection(collection)

export const db = {
    global: getCollection('global'),
    contacts: getCollection('contacts'),
    pages: getCollection('pages'),
    users: getCollection('users'),
    userTempCode: getCollection('userTempCode'),
    authLog: getCollection('authLog'),
    contactsCategories: getCollection('contactsCategories'),
    topOrderDoc: getCollection('global').doc('topOrder'),
}

export const provider = new firebase.auth.GoogleAuthProvider();
export const firebaseAuth = firebase.auth();
export const firebaseStorage = firebase.storage();
export const FieldValue = firebase.firestore.FieldValue;

export default firebase