import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import React from 'react'

import * as actions from '../../store/actions';
import ListItem from '../ListItem';
import ManualNav from '../ManualNav/ManualNav';

import styles from './ManualNavItem.module.css';

const _ManualNavItem = props => {

  const {
    navLevel,
    closeMenu,
    page,
    setCurrentPage
  } = props;

  return (props.page) ? (
    <ListItem className={[styles.ManualNavItem, styles[`Level${navLevel}`]].join(' ')}>
      <NavLink 
        className={[styles.NavLink, styles[`Level${navLevel}`]].join(' ')} 
        activeClassName={styles.Active} 
        to={'/manual/' + page.slug}
        onClick={()=>setCurrentPage(page.slug)}
        onMouseUp={closeMenu}>{props.page.title}</NavLink>
      {props.page.subpages ? <ManualNav pagelist={page.subpages} navLevel={navLevel + 1} closeMenu={closeMenu} /> : null }
    </ListItem>
  ) : null;
}

const mapStateToProps = ({pages}, ownProps) => {
  return {
      page: pages.pages ? pages.pages[ownProps.id] : false
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setCurrentPage: (slug) => dispatch(actions.setCurrentPage(slug))
  }
}

const ManualNavItem = connect(mapStateToProps,mapDispatchToProps)(_ManualNavItem) //Make new component so that this works recursively

export default ManualNavItem