import React from 'react'
import styles from './ManualNav.module.css';
import List from '../List';
import ManualNavItem from '../ManualNavItem'

const ManualNav = props => {

  return (
    <List className={styles.ManualNav}>
      {props.pagelist.length > 0 ? props.pagelist.map( id => <ManualNavItem key={id} id={id} {...props} />) : null} 
    </List>
  )
}

export default ManualNav