
import { connect } from 'react-redux';
import React, {useState} from 'react'

import {dynamicSort, transformObjectToArray} from '../../util/dynamicSort'
import {Input} from '../Form'
import * as actions from '../../store/actions';
import Button from '../Button';
import checkValidity from '../../util/validation';
import ContentBlock from '../ContentBlock';
import Title from '../Title';

import styles from './AdminPageEdit.module.css';

const AdminPageEdit = props => {

  const {
    title = '',
    id = '',
    submitCallback = false,
    parentId = false,
    contentBlocksState = { //This is the initial content block.
      0: {
        header: '',
        contentHTML: ""
      }
    },
    subpages = false,
    editPage
  } = props

  const controlsInitialState = {
    title: {
      label: "Page Title",
      name: 'page-title',
      order: 0,
      elementType: 'input',
      elementConfig: {
        type: 'title',
        placeholder: 'page title'
      },
      value: title,
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      autoFocus: true
    }
  }

  const [controls, setControls] = useState(controlsInitialState);

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  const submitHandler = (event) => {

    if (event) event.preventDefault();
    const page = {
      title: controls.title.value,
      parentId: parentId,
      contentBlocks:contentBlocks,
      subpages: subpages
    }
    editPage({id: id, page:page}); //If has id then this is an edit
    submitCallback();
  }

  // Quill State

  const [contentBlocks, setContentBlocks] = useState(contentBlocksState);

  const addContentBlock = (index) => {
    setContentBlocks({...contentBlocks,[index]:{
      header: "",
      contentHTML: ''
    }})
  }
  const deleteContentBlock = (index) => {
    let contentBlocksTemp = contentBlocks
    delete contentBlocksTemp[index]
    setContentBlocks(contentBlocksTemp)
    const page = {
      title: controls.title.value,
      subpages: subpages,
      parentId: props.parentId,
      contentBlocks:contentBlocksTemp
    }
    editPage({id: id, page:{...page, }})
  }

  const editContentHTML = (index, contentHTML) => {
    setContentBlocks({...contentBlocks,[index]:{...contentBlocks[index], contentHTML:contentHTML}})
  }

  const editHeader = (index, header) => {
    setContentBlocks({...contentBlocks,[index]:{...contentBlocks[index], header:header}})
  }

  const contentBlocksArray = Object.keys(contentBlocks).map((key) => {
    return { index: Number(key), header: contentBlocks[key].header, contentHTML:contentBlocks[key].contentHTML}
  }).sort(dynamicSort('index'))

  return (
    <div className={styles.AdminPageEdit}>
      <form className={styles.Form} onSubmit={submitHandler} >
      <Title>Edit Page <Button style={{float:"right"}} >Save</Button></Title>
        {controls ? transformObjectToArray(controls).sort(dynamicSort("order")).map((control) =>
          <Input
            key={control.id}
            label={control.label}
            name={control.name}
            helperText={control.helperText}
            elementType={control.elementType}
            elementConfig={control.elementConfig}
            value={control.value}
            invalid={!control.valid}
            shouldValidate={control.validation}
            touched={control.touched}
            autoFocus={control.autoFocus}
            ref={control.ref}
            onBlur={control.onBlur}
            changed={(event) => changeHandler(event, control.id)}
            inputInlineStyle={control.inputInlineStyle}
            inputWrapperStyles={control.inputWrapperStyles}
            labelStyles={control.labelStyles} />
        ) : null}
      </form>
      <div className={styles.ContentBlocksWrapper}>
        <h3 className={styles.ContentBlockTitle}>Content</h3>
        {contentBlocksArray.map((contentBlock) => {
          return (
            <ContentBlock
              key={contentBlock.index}
              index={contentBlock.index}
              header={contentBlock.header}
              headerChangeHandler={editHeader}
              contentHTML={contentBlock.contentHTML}
              contentHTMLChangeHandler={editContentHTML}
              deleteBlockHandler={deleteContentBlock}
              />
          )
        })}
        <Button type="button" onClick={()=>addContentBlock(contentBlocksArray.length)}>Add Content Block</Button>
      </div>
    </div>
  )
}

const mapStateToProps = ({pages}, ownProps) => {
  return {
      contentBlocksState: pages.pages[ownProps.id].contentBlocks,
      subpages: pages.pages[ownProps.id].subpages
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPageContent: (payload) => dispatch(actions.getPageContent(payload)),
    editPage: (payload) => dispatch(actions.editPage(payload))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminPageEdit)