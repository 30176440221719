
import React from 'react'
import styles from './List.module.css';

const List = props => {

  const inlineStyle = {
    margin: props.margin,
    padding: props.padding,
    listStyle: props.listStyle
  }

  return (
    <ul className={props.className || styles.List}  style={inlineStyle} >{props.children}</ul>
  )
}

export default List