
import React, {useState} from 'react'
import styles from './ContentBlock.module.css';
import { Input } from '../Form';
import QuillEditor from '../QuillEditor'
import Button from '../Button'
import {dynamicSort, transformObjectToArray} from '../../util/dynamicSort'
import checkValidity from '../../util/validation';

const Contentblock = props => {

  const controlsArray = {
    title: {
      order: 0,
      elementType: 'input',
      elementConfig: {
        type: 'title',
        placeholder: 'Header (optional)'
      },
      validation: {
        required: true
      },
      value: props.header,
      valid: true,
      touched: false,
      autoFocus: true,
      inputWrapperStyles: styles.InputWrapperStyles,
      inputInlineStyle: {
        width: '500px',
        fontWeight: '700',
        margin: '0 1rem .5rem 0',
      }
    }
  }

  const [controls, setControls] = useState(controlsArray);

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
    props.headerChangeHandler(props.index, event.target.value)
  }

  return (
    <div className={styles.Contentblock} >
      {transformObjectToArray(controls).sort(dynamicSort("order")).map((control) =>
          <Input
            key={control.id}
            label={control.label}
            helperText={control.helperText}
            elementType={control.elementType}
            elementConfig={control.elementConfig}
            value={control.value}
            invalid={!control.valid}
            shouldValidate={control.validation}
            touched={control.touched}
            autoFocus={control.autoFocus}
            ref={control.ref}
            onBlur={control.onBlur}
            changed={(event) => changeHandler(event, control.id)}
            inputInlineStyle={control.inputInlineStyle}
            inputWrapperStyles={control.inputWrapperStyles}
            labelStyles={control.labelStyles} />
        )}
        <Button onClick={()=>props.deleteBlockHandler(props.index)}>Delete Block</Button>
      <QuillEditor contentHTML={props.contentHTML} index={props.index} contentHTMLChangeHandler={props.contentHTMLChangeHandler}/>
    </div>
  )
}

export default Contentblock