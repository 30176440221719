import { connect } from 'react-redux';
import {chevronDown} from 'react-icons-kit/fa/chevronDown';
import {chevronUp} from 'react-icons-kit/fa/chevronUp';
import Icon from 'react-icons-kit';
import React, {Fragment} from 'react'
import useToggle from 'react-use-toggle';

import * as actions from '../../store/actions';
import AdminAddContactCategory from '../AdminAddContactCategory';
import Button from '../Button'
import ContactListItem from '../ContactListItem'
import List from '../List';
import Modal from '../Modal'

import styles from './ContactListCategory.module.css';

const ContactListCategory = props => {
  const {categoryId,category,verified,deleteContactCategory, admin} = props

  const changePriority = (change) => {
    props.setContactCategoryOrder({
      categoryId: categoryId,
      shift: change
    })
  }

  const [editModal, toggleEdit] = useToggle(false);

  const priorityBtnStyleUp = {
    padding: '.1rem .4rem .3rem',
    borderRadius: '3px 0 0 3px',
    height: '1rem'
  }

  const priorityBtnStyleDown = {
    padding: '.1rem .4rem .3rem',
    borderRadius: '0 3px 3px 0',
    height: '1rem'
  }

  return category ? (
    <Fragment><div className={styles.ContactListCategory}>
      <h3 className={styles.CategoryHeader}>{category.name} 
        { (verified === 'admin' && admin) ? 
          <div className={styles.Buttons}>
            <div className={styles.PriorityShiftButtons}>
              <Button className='gray' style={priorityBtnStyleUp} onClick={() => changePriority(-1)}><Icon size={12} icon={chevronUp}/></Button>
              <Button className='gray' style={priorityBtnStyleDown} onClick={() => changePriority(1)}><Icon size={12} icon={chevronDown}/></Button>
            </div>
            <div className={styles.EditBtn}>
              <Button fontSize='0.8rem' onClick={toggleEdit}>Edit Category</Button>
            </div>
            <div className={styles.DeleteBtn}>
              <Button fontSize='0.8rem' onClick={()=>deleteContactCategory({categoryId:categoryId})}>Delete Category</Button>
            </div>
          </div>
          : null 
        }
      </h3>
      <List className={styles.List}>
        {category.contacts ? category.contacts.map( id => <ContactListItem key={id} id={id} admin={admin} /> ) : null}
      </List>
    </div>
    {editModal ? <Modal backdropCloseHandler={toggleEdit} maxWidth='500px'>
      <AdminAddContactCategory
        header='Edit Category Name'
        name={category.name}
        categoryId={categoryId}
        submitName="Save"
        submitCallback={toggleEdit}
      />
    </Modal> : null}
    </Fragment>
  ) : null
}
const mapStateToProps = ({contacts, auth},ownProps) => {
  return {
    category: contacts.categories ? contacts.categories[ownProps.categoryId] : false,
    categories: contacts.categories,
    verified: auth.verified
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContactCategoryOrder: payload => dispatch(actions.setContactCategoryOrder(payload)),
    deleteContactCategory: payload => dispatch(actions.deleteContactCategory(payload))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ContactListCategory)