import React from 'react';
import styles from './Form.module.css';
import Input from './Input';
import Submit from './Submit';
import {dynamicSort, transformObjectToArray} from '../../util/dynamicSort'

const Form = props => {
  return (
      <form className={props.className || styles.Form} onSubmit={props.submitHandler} >
        {props.controls ? transformObjectToArray(props.controls).sort(dynamicSort("order")).map((control) =>
          <Input
            key={control.id}
            label={control.label}
            helperText={control.helperText}
            elementType={control.elementType}
            elementConfig={control.elementConfig}
            value={control.value}
            invalid={!control.valid}
            shouldValidate={control.validation}
            touched={control.touched}
            autoFocus={control.autoFocus}
            ref={control.ref}
            name={control.name}
            onBlur={control.onBlur}
            changed={(event) => props.changeHandler(event, control.id)}
            inputInlineStyle={control.inputInlineStyle}
            inputWrapperStyles={control.inputWrapperStyles}
            labelStyles={control.labelStyles}
            className={control.className} />
        ) : null}
        {props.children}
        {(props.submitHandler) ?
          <div className={props.submitWrapperClassName}>
            <Submit className={props.submitClassName} style={props.submitStyle} name={props.submitName} />
          </div>
          : false
        }
      </form>
  )
}

Form.defaultProps = {
  submitName: "Submit"
}

export {Input, Submit}

export default Form