import React, {Fragment} from 'react';
import {createPortal} from 'react-dom';
import styles from './ImgZoom.module.css';
import Backdrop from '../Backdrop';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import { Icon } from 'react-icons-kit'
import {close} from 'react-icons-kit/fa/close';
import useWindowDimensions from '../../util/useWindowDimensions';

const ImgZoom = props => {

  const {img, backdropCloseHandler, fullwidth = false} = props;
  const { height, width } = useWindowDimensions();

  const windowAspect = width / height;
  const imgAspect = img.width / img.height

  // Create proportional size to fit in window.

  let popupWidth = (img.width > width) ? width : img.width;
  let popupHeight = (img.height > height) ? height : img.height;

  if (imgAspect < windowAspect)  {
    popupWidth = popupHeight * imgAspect;
  } else if (imgAspect > windowAspect) {
    popupHeight = popupWidth / imgAspect;
  }

  const popupStyle = fullwidth ? {width: popupWidth, height: popupHeight} : null;

  const clickHandler = (e) => {
    // `target` is the element the click was on (the div we hooked or an element
    // with in it), `currentTarget` is the div we hooked the event on
    let targetElement = e.target;
    do {
      if (targetElement === e.target.closest("IMG") || targetElement === e.target.closest("BUTTON") || targetElement === e.target.closest("SVG") || targetElement === e.target.closest("PATH")) {
          // This is a click on the img do nothing
          return;
      }
      // Go up the DOM
      targetElement = targetElement.parentNode;
    } while (targetElement);
    //console.log('click outside', targetElement);
    backdropCloseHandler()
    // Only trigger zoom if it's an img and image is wider than the window width.
    return;
  }

  return createPortal (
    <Fragment>
      <div className={styles.ImgZoom} style={popupStyle} onClick={clickHandler}>
        <button className={styles.CloseBtn} onClick={backdropCloseHandler}><Icon size={24} icon={close} /></button>
        <PinchZoomPan position='center'>
          <img src={img.src} alt="" />
        </PinchZoomPan>
      </div>
      <Backdrop show close={backdropCloseHandler} />
    </Fragment>
    , document.getElementById('root')
  )
}

export default ImgZoom