
import React from 'react'
import Layout from '../Layout'
import AppRouter from '../AppRouter';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from '../AlertTemplate';

const options = {
  position: positions.TOP_CENTER,
  timeout: 8000,
  offset: '30px',
  transition: transitions.SCALE
}

const App = props => {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Layout>
        <AppRouter />
      </Layout>
    </AlertProvider>
  )
}

export default App