export const LANDING = "/"; // also login and request access
export const MANUAL = "/manual"; //has admin editing ability
export const CONTACTS = "/contacts"; //has admin editing ability
export const SIGNUP = "/signup";
export const PASSWORD_RESET = "/password-reset";
export const FIREBASE_ACTION = "/auth-verify";
export const PASSWORD_CHANGE = "/password-change";
export const CREATE_ACCOUNT = "/create-account";
export const IOS_INSTALL = ["/app", "/ios"];

// ADMIN SECTION
export const USER_MANAGER = "/user-manager";
export const CONTENT_MANAGER = "/content-manager";
