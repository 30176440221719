import React from 'react'
import styles from './PageList.module.css';
import List from '../List';
import PageListItem from '../PageListItem';
import AdminAddPage from '../AdminAddPage';

const PageList = props => {

  const { pagelist = [], navLevel = 0, parentId = false } = props

  // Note currently limiting pages to only 2 deep

  return (
      <List className={[styles.List, styles[`Level${navLevel}`]].join(' ')}>
        
        { (pagelist.length > 0) ? pagelist.map( id => { 
          return (
            <PageListItem key={id} id={id} {...props}/>
          )}) : null }
          
        {navLevel < 2 ? <AdminAddPage parentId={parentId} /> : null}
      </List>
  )
}

export default PageList