
import ImageUploader from 'quill-image-uploader';
import React, {useState} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import ImageResize from './ImageResize/ImageResize';

import 'react-quill/dist/quill.snow.css';

import {firebaseStorage} from '../../firebase/firebase';

import styles from './QuillEditor.module.css';

let BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style',
    'class'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);

const storageRef = firebaseStorage.ref();

const quillMods = {
  toolbar: [
    [{ 'header': [3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'},
     {'indent': '-1'}, {'indent': '+1'}],
    [{ 'align': [] }],
    ['link'],
    ['image'],
    ['video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageUploader: {
    upload: file => {
      console.log(file)
      const uploadTask = storageRef.child('images/' + file.name).put(file, {
        contentType: file.type,
      })
      return new Promise((resolve, reject) => {
        uploadTask.on(
          // Listen for changes
          'state_changed',
          // Snapshot task process
          snapshot => {},
          // Error
          error => {
            console.log(error.code)
            reject()
          },
          // Completion
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then( downloadURL => {
              console.log('File available at', downloadURL);
              resolve(downloadURL);
            });
          }
        )
      });
    }
  },
  imageResize:{
  },
}

const quillFormats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'align',
  'link',
  'image',
  'video',
  'style',
  'width',
  'class'
]

const QuillEditor = props => {

  const [quillState,setQuill] = useState(props.contentHTML)

  const onChange = (value) => {
    setQuill(value);
    props.contentHTMLChangeHandler(props.index,value)
  }

  return (
    <div className={styles.Quill}>
      {props.label ? <label className={styles.Label}>{props.label}</label> : null}
      <ReactQuill value={quillState} onChange={onChange} formats={quillFormats} modules={quillMods} ref={props.quillRef}/>
    </div>
  )
}

export default QuillEditor