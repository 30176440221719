import * as actionTypes from '../actions/actionTypes';
//import { update } from 'immutable';

export const globalReducer = (state = {}, action) => {

    switch (action.type) {
        case actionTypes.SUBSCRIBE_LISTENER:
            return {...state, isListening: {...state.isListening, [action.listener]: true} }
        case actionTypes.UNSUBSCRIBE_LISTENER:
            return {...state, isListening: {...state.isListening, [action.listener]: false} }
        case actionTypes.ALERT_MSG:
            return {...state, alert: {message: action.message, alertType: action.alertType}}
        case actionTypes.SIGN_OUT:
            return {}
        default: return state
    }
}

export default globalReducer