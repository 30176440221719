export function dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while(result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i],{ignoreCase: true})(obj1, obj2); //TODO: rework this so it has option to ignore case for each property?
            i++;
        }
        return result;
    }
}

export function dynamicSort(property, options = {}) {
    const {ignoreCase = false} = options;
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        let aa = a[property]
        let bb = b[property]
        if (ignoreCase && typeof aa === 'string') aa = aa.toLowerCase();
        if (ignoreCase && typeof bb === 'string') bb = bb.toLowerCase();
        var result = (aa < bb) ? -1 : (aa > bb) ? 1 : 0;
        return result * sortOrder;
    }
}

export function transformObjectToArray(object,keyName = 'id') {
   return object ? Object.keys(object).map((key)=>{ return {...object[key], [keyName]: key} }) : false
}

export const transformArrayToObject = (array, keyName = 'id') => array.reduce((obj, item) => {
    obj[item[keyName]] = item
    return obj
}, {})