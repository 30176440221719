
import React from 'react'
import styles from './Toolbar.module.css';
import {createPortal} from 'react-dom';

const Toolbar = props => {

  return createPortal (
    <div className={styles.Toolbar}>
      {props.children}
    </div>,
    document.getElementById('root')
  )
}


export default Toolbar