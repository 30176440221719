
import { connect } from 'react-redux';
import React, {useEffect,useState} from 'react'
import styled from 'styled-components'

import {dynamicSort} from '../../util/dynamicSort';
import AdminPageEdit from '../AdminPageEdit';
import Button from '../Button';
import ContentPaneBlock from '../ContentPaneBlock'
import ContentTab from '../ContentTab'
import PageList from '../PageList';
import Title from '../Title';

const Wrapper = styled.div`
  box-sizing: content-box;
  & p > br {
    display: none;
  }
`

const TabBarWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  box-sizing: content-box;
  @media(min-width: 800px) {
    padding-right: calc(var(--sidebar_width)  + var(--content_pane_side_margin));
    padding-left: calc(env(safe-area-inset-left) + var(--content_pane_side_margin));
    box-sizing: border-box;
  }
`

const EditBtnWrapper = styled.div`
  padding-right: 1rem;
  @media(min-width: 800px) {
    padding-right: calc(var(--sidebar_width) + var(--content_pane_side_margin));
    padding-left: calc(env(safe-area-inset-left) + var(--content_pane_side_margin));
    box-sizing: border-box;
  }
`

const ContentBlockWrapper = styled.div`
  padding-top: var(--content_pane_header );
`

const TabBar = styled.nav`
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

const Header = styled.div`
  background: var(--lavender-very-light);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: calc(env(safe-area-inset-top) + var(--mobile_header_height_with_padding) + 0.6rem);
  border-bottom: 2px solid var(--burgundy-dark);
  height: var(--content_pane_header);
  width: 100%;
  box-sizing: content-box;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const SubpageList = styled.div`
  margin: 2rem 0 0;
  border-top: 1px solid #aaa;
  padding-top: 1rem;
`

const ContentTitle = styled.h1`
  font-size: 1.4rem;
  padding-left: calc(env(safe-area-inset-left) + var(--content_pane_side_margin));
  padding-right: calc(env(safe-area-inset-right) + var(--content_pane_side_margin));
  margin: 0;
  @media(min-width: 800px) {
    padding-right: calc(var(--sidebar_width) + var(--content_pane_side_margin) + 2rem);
  }
`

const ContentPane = props => {

  const [editMode, setEdit] = useState(false)
  const toggleEdit = () => setEdit(!editMode)

  const [currentContentBlock, setContentBlock] = useState(0);

  const tabClick = (index) => {
    setContentBlock(index)
  }

  const {id, navLevel = 0, page, contentBlocks, verified} = props

  const contentBlocksArray = contentBlocks ? Object.keys(contentBlocks).map((key) => {
    return { index: Number(key), header: contentBlocks[key].header, contentHTML:contentBlocks[key].contentHTML}
  }).sort(dynamicSort('index')) : false

  useEffect(()=>{
    setEdit(false) //If the nav changes then go back to view mode and return to first content block
    setContentBlock(0)
  },[id,setEdit,setContentBlock])

  return editMode ? (
    <Wrapper>
      <AdminPageEdit
            submitCallback={toggleEdit}
            id={id}
            parentId={page.parentId}
            title={page.title}
            contentBlocks={page.contentBlocks}
             />
      {!page.parentId ? <SubpageList><Title>SubPages</Title><PageList pagelist={page.subpages} parentId={id} navLevel={navLevel + 1} /> </SubpageList> : null}
    </Wrapper>
  ) : (
    <Wrapper>
      <Header>
        <ContentTitle>{page.title}</ContentTitle>
        {(verified === 'admin') ? <EditBtnWrapper><Button fontSize='0.8rem' onClick={toggleEdit} >Edit</Button></EditBtnWrapper> : null}
        {(contentBlocksArray && contentBlocksArray.length > 1) ?
          <TabBarWrapper>
            <TabBar>
              {contentBlocksArray.map( (contentBlock, index) => <ContentTab key={index} index={index} onClick={() => tabClick(index)} active={currentContentBlock}>{(contentBlock.header) ? contentBlock.header : 'Introduction'}</ContentTab> )}
            </TabBar>
          </TabBarWrapper>
        : null}
      </Header>
      {(contentBlocksArray[currentContentBlock]) ? <ContentBlockWrapper> <ContentPaneBlock header={contentBlocksArray[currentContentBlock].header} contentHTML={contentBlocksArray[currentContentBlock].contentHTML} /> </ContentBlockWrapper> : null}
    </Wrapper>
  )
}

const mapStateToProps = ({pages, auth}, ownProps) => {
  return {
      page: pages.pages[ownProps.id],
      contentBlocks: pages.pages[ownProps.id].contentBlocks,
      verified: auth.verified
  }
}

export default connect(mapStateToProps)(ContentPane)