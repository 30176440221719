import * as actionTypes from '../actions/actionTypes';
//import { update } from 'immutable';

export const authReducer = (state = {}, action) => {

    switch (action.type) {
        case actionTypes.SIGN_IN:
            return {...state, user: action.user}
        case actionTypes.SIGN_OUT:
            return {}
        case actionTypes.VERIFIED:
            return {...state, verified: action.verified}
        default: return state
    }
}

export default authReducer