import { connect } from 'react-redux';
import React from 'react';
import styled from 'styled-components'

import {transformObjectToArray,dynamicSortMultiple} from '../../util/dynamicSort';

import List from '../List';
import UserListItem from '../UserListItem';
import Button from '../Button';

import styles from './UserList.module.css';

const TopButtonsBox = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
`

const UserList = props => {
  const {userList, deleteUser, changeRole} = props

  const userListArray = transformObjectToArray(userList).sort(dynamicSortMultiple('role','email'))
  const userEmails = userListArray.map( item => item.email)

  const userEmailsString = userEmails.reduce((total, item) => `${total}; ${item}`)

  return (
    <div className={styles.UserList}>
      <h2 className={styles.UserListTitle}>User List</h2>
      <TopButtonsBox><Button type="button" onClick={() => {navigator.clipboard.writeText(userEmailsString)}}>Copy All Emails to Clipboard</Button></TopButtonsBox>
      <List className={styles.UserList}>
        {userListArray.map( user => <UserListItem key={user.id} user={user} deleteUser={deleteUser} changeRole={changeRole} />)}
      </List>
    </div>
  )
}

const mapStateToProps = ({users}) => {
  return {
      userList: users.userList
  }
}
export default connect(mapStateToProps, null)(UserList)