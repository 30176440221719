import React, {Fragment} from 'react';

import styles from './Input.module.css';

const Input = ( props ) => {
    let inputElement = null;
    const inputClasses = [styles.InputElement, props.className];

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(styles.Invalid);
    }

    switch ( props.elementType ) {
        case ( 'input' ):
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                autoFocus={props.autoFocus}
                onBlur={props.onBlur}
                ref={props.ref}
                onChange={props.changed}
                id={props.name}
                name={props.name}
                style={props.inputInlineStyle} />
            break;
        case ( 'textarea' ):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                autoFocus={props.autoFocus}
                onBlur={props.onBlur}
                onChange={props.changed}
                id={props.name}
                name={props.name}
                style={props.inputInlineStyle} />;
            break;
        case ( 'select' ):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    autoFocus={props.autoFocus}
                    onBlur={props.onBlur}
                    onChange={props.changed}
                    id={props.name}
                    name={props.name}
                    style={props.inputInlineStyle}>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value} disabled={option.disabled} >
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            );
            break;
        case ( 'checkbox' ):
            inputElement = (
                <Fragment>
                    {props.elementConfig.options.map(option => (
                        <span key={option.value}><input type="checkbox" checked={option.checked} id={option.value} name={option.value} value={option.value} className={inputClasses.join(' ')} onChange={props.changed} onBlur={props.onBlur} /><label htmlFor={option.value}>{option.displayValue}</label></span>
                    ))}
                </Fragment>
            );
            break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                autoFocus={props.autoFocus}
                onChange={props.changed}
                style={props.inputInlineStyle} />;
    }

    return (
        <div className={props.inputWrapperStyles || styles.InputWrapper}>
            {props.label ? <label htmlFor={props.name} className={props.labelStyles || styles.Label}>{props.label}</label> : null}
            {inputElement}
            {props.helperText ? <span className={styles.HelperText}>{props.helperText}</span>: null}
        </div>
    );

};

Input.defaultProps = {
    className: null
}

export default Input;