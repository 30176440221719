
import { connect } from 'react-redux';
import { useAlert } from 'react-alert'
import { withRouter } from "react-router-dom";
import React, {useState, useEffect, Fragment} from 'react'
import styled from 'styled-components'

import * as actions from '../../store/actions';
import * as ROUTES from '../../constants/routes'
import Button from '../Button';
import checkValidity from '../../util/validation';
import Form from '../Form';

import styles from './Auth.module.css';

const Wrapper = styled.div``
const LoginOptionsWrapper = styled.div`
  margin-top: 1rem;
`
const Paragraph = styled.p`
  text-align: left;
  color: #fff;
`

const Auth = props => {

  const alert = useAlert()

  const submitButtonSignIn = {name: 'Login', handler: props.signIn, toggleLink: 'Request User Account'}
  /* const submitButtonSignUp = {name: 'Request Account', handler: props.requestAccess, toggleLink: 'Already have access? Login here'} */
  const [submitButton,setSubmitButton] = useState(submitButtonSignIn)

  /* const toggleSubmit = () => {
    (submitButton.name === 'Login') ? setSubmitButton(submitButtonSignUp) : setSubmitButton(submitButtonSignIn)
  } */

  const [controls, setControls] = useState({
    email: {
      order: 0,
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Email',
        autoCorrect: 'off',
        autoCapitalize: 'none',
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
        //isUCSFemail: true,
      },
      valid: false,
      touched: false,
      autoFocus: true
    },
    password: {
      order: 1,
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Password'
      },
      value: '',
      validation: {
        required: true,
        minLength: 8
      },
      valid: false,
      touched: false
    }
  });

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  useEffect(()=> {
    return ()=> { setControls(controls => {return {...controls,email:{...controls.email,value:'',valid:'false',touched:'false'},password:{...controls.password,value:'',valid:'false',touched:'false'}}})}
  },[])

  const submitHandler = (event) => {
    event.preventDefault();
    if (!controls.email.valid) alert.error('Email must be a valid ucsf.edu address')
    if (!controls.password.valid) alert.error('Password must be at least 8 characters')
    if (controls.email.valid && controls.password.valid) {
      submitButton.handler({
        email: controls.email.value.toLowerCase(),
        password: controls.password.value
      })
      if (submitButton.name === 'Request Account') {setSubmitButton(submitButtonSignIn)}
    }
  }

  const btnStyle = {
    marginBottom: '1rem'
  }

  return  (
    <Wrapper>
      {(submitButton.name === 'Request Account') ? <Paragraph>Request access to the house manual by adding your email and a password below.</Paragraph> : null }
      {(submitButton) ? <Fragment><Form className={styles.AdminAddPageForm}
        controls={controls}
        changeHandler={changeHandler}
        submitHandler={submitHandler}
        submitName={submitButton.name}
        submitStyle={{fontSize:'1.2rem'}}
      />
        <LoginOptionsWrapper>
          <Button className='gray block' style={btnStyle} onClick={()=> props.history.push(ROUTES.PASSWORD_RESET)}>Forget Password?</Button>
        </LoginOptionsWrapper>
      </Fragment>
      : <Paragraph>Thank you. A verification email has been sent to your email address. Once you verify your address a member of our staff will review and grant you access.</Paragraph>}
    </Wrapper>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    signIn: (payload) => dispatch(actions.signIn(payload)),
    requestAccess: (payload) => dispatch(actions.requestAccess(payload))
  }
}

export default connect(null,mapDispatchToProps)(withRouter(Auth))