import { connect } from 'react-redux';
import React, {useState} from 'react'

import * as actions from '../../store/actions';
import checkValidity from '../../util/validation';
import Form from '../Form'

import styles from './AdminAddContactCategory.module.css';

const AdminAddContactCategory = props => {

  const {
    categoryId,
    category = {},
    header = 'Add Category',
    submitName = 'Add',
    submitCallback = ()=>{},
    addContactCategory,
    editContactCategory
  } = props

  const controlsInitialState = {
    category: {
      label: false,
      order: 1,
      elementType: 'input',
      elementConfig: {
        type: 'category',
        placeholder: 'Category name'
      },
      value: category.name || '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      inputWrapperStyles: styles.TitleWrapper,
      autoFocus: true
    }
  }

  const [controls, setControls] = useState(controlsInitialState);

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  console.log(category);

  const submitHandler = (event) => {
    event.preventDefault();
    const newCategory = {
      name: controls.category.value,
      contacts: category.contacts || []
    }
    categoryId ? editContactCategory({category: newCategory, categoryId:categoryId}) : addContactCategory(newCategory);
    setControls( {...controls,category: {...controls.category, valid: false, touched: false, value: '' } } );
    submitCallback();
  }

  return (
    <div className={styles.AdminAddContactCategory}>
      <h3 className={styles.Header}>{header}</h3>
      <Form className={styles.AdminAddCategoryForm} 
        controls={controls}
        changeHandler={changeHandler}
        submitHandler={submitHandler} 
        submitName={submitName}
        submitWrapperClassName={styles.SubmitWrapper}
        submitClassName={styles.Submit}
      />
    </div>
  )
}

const mapStateToProps = ({contacts},ownProps) => {
  return {
    category: contacts.categories[ownProps.categoryId]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addContactCategory: (payload) => dispatch(actions.addContactCategory(payload)),
    editContactCategory: (payload) => dispatch(actions.editContactCategory(payload))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminAddContactCategory)