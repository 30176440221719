import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import React, {Fragment} from 'react'
import styled from 'styled-components'
import AdminAddUser from '../AdminAddUser/AdminAddUser';
import ContentWrapper from '../ContentWrapper';
import MobileHeader from '../MobileHeader';
import UserList from '../UserList';
import UserTokens from '../UserTokens';

import styles from './AdminUserManager.module.css';

import packageJson from '../../../package.json';

const Title = styled.h1`
  font-size: 1.6rem;
  margin: 0 0 1rem;
  width: 100%;
  color: var(--navy-blue-dark)
`

const AdminUserContent = ({show}) => {
  if (show) {
    return (
      <Fragment>
        <div className={styles.UserListWrapper}>
          <UserList />
        </div>
        <div className={styles.AddUserWrapper}>
          <AdminAddUser />
          <UserTokens />
        </div>
      </Fragment>
    )
  } else return <div className={styles.LoaderWrapper}> <Loader type="Grid" color="#5e155e" height={100} width={100}  /></div>
}

const AdminUserManager = props => {
  return (
    <ContentWrapper>
      <div className={styles.AdminUserManager}>
        <MobileHeader>Admin User Manager</MobileHeader>
        <Title>Admin (app version: {packageJson.version})</Title>
        <AdminUserContent show={props.userList} />
      </div>
    </ContentWrapper>
  )
}

const mapStateToProps = ({users}) => {
  return {
      userList: users.userList
  }
}

export default connect(mapStateToProps, null)(AdminUserManager)