import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  font-size: .9rem;
  flex: 0 4 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: var(--lavender-light);
  color: var(--gray-dark);
  padding: .2rem .4rem .3rem;
  border-radius: 4px 4px 0 0;
  border-top: 1px solid var(--lavender);
  border-left: 1px solid var(--lavender);
  border-right: 1px solid var(--lavender);
  border-bottom: 0;
  position: relative;
  margin: 0;

  &.active {
    background: var(--light-orange);
    flex: 0 0 auto;
  }

  &.prev, &.next {
    flex: 0 1 auto;
  }

  @media (hover: hover) {
    &:hover {
        background: var(--light-orange);
        border-top: 1px solid var(--lavender);
        border-left: 1px solid var(--lavender);
        border-right: 1px solid var(--lavender);
        border-bottom: 0;
    }
  }
`

const ContentTab = props => {
  const {onClick, index, active, children} = props

  let className

  switch (index) {
    case (active): className = 'active'; break
    case (active - 1): className = 'prev'; break;
    case (active + 1): className = 'next'; break;
    default: break;
  }

  return (
    <Button className={className} onClick={onClick}>{children}</Button>
  )
}

export default ContentTab