import * as actionTypes from '../actions/actionTypes';
import update from 'immutability-helper';

function removeByKey(myObj, deleteKey) {
    return Object.keys(myObj)
      .filter(key => key !== deleteKey)
      .reduce((result, current) => {
        result[current] = myObj[current];
        return result;
    }, {});
}

export const pagesReducer = (state = {}, action) => {

    switch (action.type) {
        case actionTypes.GET_PAGES:
            return {...state, pages: action.pages}
        case actionTypes.SET_TOPLEVELPAGES:
            return {...state, topLevelPages: action.topLevelPages}
        case actionTypes.ADD_PAGE:
            return {...state, pages: {...state.pages, [action.id]:action.page}}
        case actionTypes.EDIT_PAGE:
            return {...state, pages: update(state.pages, {
                [action.id]:{
                    $merge:action.page
                }
            })}
        case actionTypes.DELETE_PAGE:
            return {...state, pages: removeByKey(state.pages,action.id)}
        case actionTypes.ADD_PAGE_CONTENT:
            return {...state, pages: update(state.pages, {
                [action.id]:{
                    $merge:{
                        content:{
                            [action.contentId]:action.content
                        }
                    }
                }
            })}
        case actionTypes.GET_PAGE_CONTENT:
            return {...state, pages: update(state.pages, {
                [action.id]:{
                    $merge:{
                        contentBlocks:action.contentBlocks
                    }
                }
            })}
        case actionTypes.SET_SUBPAGE_ARRAY:
            return {...state, pages: update(state.pages, {
                [action.parentId]:{
                    subpageIds: {$set:action.subPagesArray}
                }
            })}
        case actionTypes.SET_CURRENT_PAGE:
            return {...state, currentPage: action.slug }
        case actionTypes.SAVE_SLUGGED_PAGES:
            return {...state, sluggedPages: action.sluggedPages}
        case actionTypes.SET_TOPPAGE_ORDER:
            return {...state, topLevelPages: action.pageArray}
        case actionTypes.SET_SUBPAGE_ORDER:
            return {...state, pages: update(state.pages, {
                [action.parentId]:{
                    subpages: {$set:action.pageArray}
                }
            })}
        case actionTypes.SIGN_OUT:
            return {}
        default: return state
    }
}

export default pagesReducer