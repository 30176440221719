import { connect } from "react-redux";
import React, { Fragment } from "react";
import styled from "styled-components";

import * as actions from "../../store/actions";
import * as ROUTES from "../../constants/routes";
import Auth from "../Auth";
import Button from "../Button";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.svg";

const Wrapper = styled.div`
	text-align: center;
	background-color: #993399;
	background: linear-gradient(
		135deg,
		var(--burgundy-light) 0%,
		var(--burgundy-mid) 23%,
		var(--burgundy) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	background-size: 100%;
	background-repeat: no-repeat;
	height: 100vh;
`;

const Title = styled.div`
	font-size: 3rem;
	padding: 3rem 0 1rem;
	padding-top: calc(env(safe-area-inset-top) + 3rem);
`;
const Logo = styled.img`
	max-width: 300px;
	width: 60%;
	height: auto;
`;

const AuthWrapper = styled.div`
	width: 300px;
	max-width: 90%;
	margin: 0 auto;
	color: var(--off-white);
`;

const StyledLink = styled(Link)`
	margin: 4rem 0 2rem;
	color: #fff;
	display: block;
`;

const AuthSwitch = (props) => {
	switch (props.verified) {
		case "reader":
		case "admin":
			return (
				<Fragment>
					<Button
						className="block large"
						onClick={() => props.history.push("/manual")}
					>
						Open Manual
					</Button>
					<StyledLink to={ROUTES.IOS_INSTALL}>
						Add to Home Screen as an App
					</StyledLink>
					<Button
						className="block gray"
						onClick={() =>
							props.history.push(ROUTES.PASSWORD_CHANGE)
						}
					>
						Change Password?
					</Button>
					<Button className="block gray" onClick={props.signOut}>
						Logout
					</Button>
				</Fragment>
			);
		default:
			return <Auth />;
	}
};

const SplashScreen = (props) => {
	return (
		<Wrapper>
			<Title>
				<Logo src={logo} alt="UCSF Pediatric Surgery House Manual" />
			</Title>
			<AuthWrapper>
				<AuthSwitch {...props} />
			</AuthWrapper>
			<StyledLink to={ROUTES.IOS_INSTALL}>
				Add to Home Screen as an App
			</StyledLink>
		</Wrapper>
	);
};

const mapStateToProps = ({ auth }) => {
	return {
		verified: auth.verified,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => dispatch(actions.signOut()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
