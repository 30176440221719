
import React, {useState, useEffect} from 'react'
import styles from './AdminAddUser.module.css';
import Form from '../Form';
import checkValidity from '../../util/validation';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

const AdminAddUser = props => {

  const {createUserTempCode} = props

  const defaultControls = {
    email: {
      order: 0,
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Email',
        autoCorrect: 'off',
        autoCapitalize: 'none',
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
        //isUCSFemail: true,
      },
      valid: false,
      touched: false
    },
    role: {
      order: 1,
      elementType: 'select',
      elementConfig: {
        type: 'role',
        options: [
          {value: "reader", displayValue: "reader"},
          {value: "admin", displayValue: "admin"},
        ]
      },
      value: 'reader',
      validation: {
        required: true,
      },
      valid: false,
      touched: false
    }
  }

  const [controls, setControls] = useState(defaultControls);

  const changeHandler = (event, id) => {
    const updatedControls = {
      ...controls,
      [id]: {
        ...controls[id],
        value: event.target.value,
        valid: checkValidity(event.target.value,controls[id].validation),
        touched: true
      }
    }
    setControls( updatedControls )
  }

  useEffect(()=> {
    return ()=> { setControls(controls => {return {...controls,email:{...controls.email,value:'',valid:'false',touched:'false'},password:{...controls.password,value:'',valid:'false',touched:'false'}}})}
  },[])

  const submitHandler = (event) => {
    event.preventDefault();
    createUserTempCode({
      email: controls.email.value.toLowerCase(),
      role: controls.role.value
    })
    setControls( defaultControls )
  }

  return (
    <div className={styles.AdminAddUser}>
      <h2>Add New User</h2>
      <p>User will be emailed with a link to create an account.</p>
      <Form className={styles.AdminAddPageForm}
        controls={controls}
        changeHandler={changeHandler}
        submitHandler={submitHandler}
        submitName="Add User"
      />
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    createUserTempCode: (payload) => dispatch(actions.createUserTempCode(payload))
  }
}

export default connect(null,mapDispatchToProps)(AdminAddUser)