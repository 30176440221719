import * as actionTypes from '../actions/actionTypes';
//import { update } from 'immutable';

export const usersReducer = (state = {}, action) => {

    switch (action.type) {
        case actionTypes.CREATE_USER:
            return {...state, userList:action.users}
        case actionTypes.GET_USER_LIST:
            return {...state, userList:action.users}
        case actionTypes.DELETE_USER:
            const {[action.uid]:deletedValue, ...newUserList} = state.userList
            return {...state, userList: newUserList}
        case actionTypes.CHANGE_ROLE:
            return {...state, userList: {
                ...state.userList, [action.uid]:{email: action.email, role: action.role}
            }}
        case actionTypes.GET_USER_TIMESTAMPS:
            return {...state, userTimestamps: action.users}
        case actionTypes.GET_USER_TOKENS:
            return {...state, userTokens:action.tokens}
        case actionTypes.SIGN_OUT:
            return {}
        default: return state
    }
}

export default usersReducer