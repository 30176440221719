import * as actionTypes from '../actions/actionTypes';

function removeByKey(myObj, deleteKey) {
    return Object.keys(myObj)
      .filter(key => key !== deleteKey)
      .reduce((result, current) => {
        result[current] = myObj[current];
        return result;
    }, {});
}

export const contactsReducer = (state = {}, action) => {

    switch (action.type) {
        case actionTypes.GET_CONTACTS:
            return {...state, contacts: action.contacts}
        case actionTypes.ADD_CONTACT:
            return {...state,
                contacts: {...state.contacts, [action.id]:action.contact}
            }
        case actionTypes.DELETE_CONTACT:
            return {...state,
                contacts: removeByKey(state.contacts,action.id)
            }
        case actionTypes.EDIT_CONTACT:
            return {...state,
                contacts: {...state.contacts, [action.id]:action.contact}
            }
        case actionTypes.GET_CONTACT_CATEGORIES:
            return {...state, categories: action.categories}
        case actionTypes.GET_CONTACT_CATEGORIES_ORDER:
            return {...state, categoryOrder: action.categoryOrder}
        case actionTypes.ADD_CONTACT_CATEGORY:
            return {...state, categories: {...state.categories, [action.categoryId]:action.contactCategory}}
        case actionTypes.DELETE_CONTACT_CATEGORY:
            return {...state, categories: removeByKey(state.categories,action.categoryId)}
        case actionTypes.EDIT_CONTACT_CATEGORY:
            return {...state,
                categories: {...state.categories, [action.categoryId]:action.category}
            }
        case actionTypes.SET_CONTACT_CATEGORY_ORDER:
            return {...state, categoryOrder: action.categoryOrder}
        case actionTypes.SET_CONTACT_ORDER: //USED for adding/editing/deleting
            return {...state,
                categories: {...state.categories, [action.categoryId]:{...state.categories[action.categoryId], contacts: action.contactArray}}
            }
        case actionTypes.SIGN_OUT:
            return {}
        default: return state
    }
}

export default contactsReducer