
import React from 'react'
import styles from './ListItem.module.css';

const ListItem = props => {

  const inlineStyle = {
    margin: props.margin,
    padding: props.padding,
    listStyle: props.listStyle,
    border: props.border
  }

  return (
    <li className={props.className|| styles.Listitem} style={inlineStyle} >{props.children}</li>
  )
}

export default ListItem