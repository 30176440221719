import { connect } from 'react-redux';
import React, {useState} from 'react'

import * as actions from '../../store/actions';
import Button from '../Button';
import ListItem from '../ListItem';
import Form from '../Form';
import Input from '../Form/Input';

import styles from './UserListItem.module.css';

const UserListItem = props => {
  const {user, changeRole, deleteUser} = props
  return (
    <ListItem key={user.id} className={styles.UserListItem}>
      <span className={styles.Email}>{user.email}</span>
      <RoleSwitch user={user} changeRole={changeRole} />
      <span className={styles.Buttons}>
        <Button fontSize='0.8rem' onClick={()=> deleteUser({uid: user.id})}>Delete</Button>
      </span>
    </ListItem>
  )
}

const RoleSwitch = props => {

  const {user, changeRole} = props

  const roleConfig = {
    type: 'role',
    options: [
      {value: "reader", displayValue: "reader"},
      {value: "admin", displayValue: "admin"},
    ]
  }

  const [roleSelected, setRoleSelected] = useState(user.role);

  const changeHandler = (event) => {
    setRoleSelected( event.target.value )
    changeRole({uid: user.id, email: user.email, role: event.target.value})
  }

  return (
    <Form className={styles.RoleSwitch}>
      <Input
        elementType='select'
        changed={changeHandler}
        className={styles.RoleSelect}
        inputWrapperStyles={styles.InputWrapper}
        elementConfig={roleConfig}
        value={roleSelected}
      />
    </Form>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    deleteUser: (payload) => dispatch(actions.deleteUser(payload)),
    changeRole: (payload) => dispatch(actions.changeRole(payload))
  }
}

export default connect(null, mapDispatchToProps)(UserListItem)